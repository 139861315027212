<template>
  <Dialog
    v-model:visible="paymentDialog"
    :style="{ width: '100vw' }"
    :maximizable="true"
    :modal="true"
    position="top"
    @hide="confirmPaymentCancel"
    class="p-fluid"
  >
    <template #header>
      <h6 class="p-dialog-titlebar p-dialog-titlebar-icon">
        <i class="pi pi-money-bill py-icon-color"></i> {{ dialogTilte }}
      </h6>
    </template>
    <!--row open-->
    <div class="row text-center">
      <div class="col-md-12">
        <p class="pay-size-bx">
          <label class="py-span">
            <img src="@/assets/pay/needle.png" />
            Needle
            <input
              type="radio"
              checked
              @click="paymentMethod('Needle')"
              name="payment_method"
            />
          </label>
        </p>
        <p class="pay-size-bx">
          <label class="py-span">
            <img src="@/assets/pay/cash.png" />
            Cash
            <input
              type="radio"
              @click="paymentMethod('Cash')"
              name="payment_method"
            />
          </label>
        </p>
        <p class="pay-size-bx ml-5">
          <label class="py-span">
            <img src="@/assets/pay/creditcard.png" />
            Card
            <input
              type="radio"
              @click="paymentMethod('Card')"
              name="payment_method"
            />
          </label>
        </p>
        <p class="pay-size-bx">
          <label class="py-span">
            <img src="@/assets/pay/manual.png" />
            Manual
            <input
              type="radio"
              @click="paymentMethod('Manual')"
              name="payment_method"
            />
          </label>
        </p>
        <p class="pay-size-bx ml-5">
          <label class="py-span">
            <img src="@/assets/pay/split.png" />
            Tip
            <input
              type="radio"
              @click="paymentMethod('Tip')"
              name="payment_method"
            />
          </label>
        </p>
        <p class="pay-size-bx" v-if="restriction != 'Yes'">
          <label class="py-span">
            <img src="@/assets/pay/paylater.png" />
            Pay Later
            <input
              type="radio"
              @click="paymentMethod('Pay Later')"
              name="payment_method"
            />
          </label>
        </p>
      </div>
    </div>
    <div class="row py-description">
      <div style="height: 0.2em; background-color: #fff" class="col-md-12">
        <ProgressBar
          v-if="progressBar"
          mode="indeterminate"
          style="height: 0.2em"
        />
      </div>
      <div class="col-md-2 col-sm-12 content-height p-pl-1 p-pr-0">
        <h5>
          (<i class="pi pi-dollar"></i>) Amount Due
          <input
            type="text"
            :value="paymentAction.amountLeft.toFixed(2)"
            readonly
            class="form-control py-customize-bx mt-1 py-balance-due"
          />
        </h5>
        <h5 class="mt-2">
          (<i class="pi pi-dollar"></i>) Tendered
          <input
            style="color: green"
            type="text"
            readonly
            :value="paymentAction.tendered"
            class="form-control py-customize-bx mt-1"
          />
        </h5>
        <h5 class="mt-2">
          (<i class="pi pi-dollar"></i>) Change
          <input
            type="number"
            readonly
            :value="paymentAction.change"
            class="form-control py-customize-bx mt-1"
          />
        </h5>
        <h5 class="mt-2">
          (<i class="pi pi-dollar"></i>) Round Off
          <input
            type="number"
            readonly
            :value="paymentAction.roundOff"
            class="form-control py-customize-bx mt-1"
          />
        </h5>
      </div>
      <div class="col-md-3 col-sm-12">
        <div class="content-height">
          <h5>
            <i class="pi pi-file-o py-icon-color" aria-hidden="true"></i>
            Key Pad
          </h5>
          <!-- keypad -->
          <div
            class="btn-group-vertical text-center"
            role="group"
            aria-label="Basic example"
          >
            <div class="btn-group btn-group-lg">
              <button
                type="button"
                @click="amountNumpad(1)"
                class="btn-numpad col-sm-4"
              >
                1
              </button>
              <button
                type="button"
                @click="amountNumpad(2)"
                class="btn-numpad col-sm-4"
              >
                2
              </button>
              <button
                type="button"
                @click="amountNumpad(3)"
                class="btn-numpad col-sm-4"
              >
                3
              </button>
            </div>
            <div class="btn-group btn-group-lg">
              <button type="button" @click="amountNumpad(4)" class="btn-numpad">
                4
              </button>
              <button type="button" @click="amountNumpad(5)" class="btn-numpad">
                5
              </button>
              <button type="button" @click="amountNumpad(6)" class="btn-numpad">
                6
              </button>
            </div>
            <div class="btn-group btn-group-lg">
              <button type="button" @click="amountNumpad(7)" class="btn-numpad">
                7
              </button>
              <button type="button" @click="amountNumpad(8)" class="btn-numpad">
                8
              </button>
              <button type="button" @click="amountNumpad(9)" class="btn-numpad">
                9
              </button>
            </div>
            <div class="btn-group btn-group-lg">
              <button
                type="button"
                @click="amountNumpad('.')"
                class="btn-numpad"
              >
                .
              </button>
              <button type="button" @click="amountNumpad(0)" class="btn-numpad">
                0
              </button>
              <button
                type="button"
                @click="amountNumpad('del')"
                class="btn-numpad-danger"
              >
                Del
              </button>
            </div>
            <div class="btn-group btn-group-lg" style="margin-top: 1px">
              <Button
                label="Exact"
                icon="pi pi-ticket"
                class="p-button-lg p-button-primary p-4"
                @click="exactAmount()"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12">
        <div v-if="paymentMethodType == 'Card'" class="content-height">
          <h5>
            <i class="pi pi-id-card py-icon-color" aria-hidden="true"></i>
            Scan Any Debit/Credit/Gift Card
          </h5>
          <div class="transactions-card-manual">
            <table class="table table-bordered table-hover">
              <template v-for="(item, index) in paymentList" :key="item">
                <tr>
                  <td
                    style="background-color: #004c97; color: #fff"
                    colspan="2"
                  >
                    <b>Transaction No {{ index + 1 }} </b>
                  </td>
                </tr>
                <tr>
                  <td>
                    Response :
                    <span style="color: green">{{ item.hostResponse }}</span>
                  </td>
                  <td>Acc No : {{ item.accountNo }}</td>
                </tr>
                <tr>
                  <td>
                    Tender : <b>{{ item.paymentType }}</b>
                  </td>
                  <td>
                    Total Amount :
                    <b>${{ item.transTotalAmount.toFixed(2) }}</b>
                  </td>
                </tr>
                <tr>
                  <td>Auth Code : {{ item.authCode }}</td>
                  <td>Terminal ID : {{ item.terminalId }}</td>
                </tr>
              </template>
              <tr style="text-align: center" v-if="paymentList.length == 0">
                <td colspan="2"><i> No Transactions Yet </i></td>
              </tr>
            </table>
          </div>
          <Button
            label="Swipe Card"
            icon="pi pi-money-bill"
            class="p-button-lg p-button-warning p-4"
            @click="termianlPayment()"
          />
        </div>
        <div v-if="paymentMethodType == 'Manual'" class="content-height">
          <h5>
            <i class="pi pi-id-card py-icon-color" aria-hidden="true"></i>
            Add Card Payments Manually
          </h5>
          <div class="transactions-card-manual">
            <div class="form-group">
              <label> Card Type</label>
              <select
                class="form-control"
                @change="clearAccountNo()"
                v-model="cardType"
              >
                <option value="Debit">Debit</option>
                <option value="Visa">Visa</option>
                <option value="MasterCard">MasterCard</option>
                <option value="Amex">Amex</option>
                <option value="Discover Card">Discover Card</option>
                <option value="Gift Card">Gift Card</option>
              </select>
            </div>
            <div class="form-group">
              <label> Account No (Last 4 digits)</label>
              <input
                type="number"
                placeholder="e.g 3217"
                v-model="acccountNo"
                class="form-control"
              />
            </div>
          </div>
          <Button
            label="Add Card Payment"
            icon="pi pi-money-bill"
            @click="addManualAmount"
            class="p-button-lg p-button-warning p-4"
          />
        </div>
        <div v-if="paymentMethodType == 'Needle'" class="content-height">
          <div class="transactions">
            <h5>
              <i class="pi pi-money-bill py-icon-color" aria-hidden="true"></i>
              Needle Points
            </h5>
            <div class="form-group">
              <h3 class="col-12">
                Points Earned : $ {{ paymentAction.needlePoints }}
              </h3>
            </div>
            <p class="p-pl-3">
              <i>Overall points earned by {{ customerName }}</i>
            </p>
          </div>
          <div class="col-md-12 p-0">
            <Button
              label="Add Needles"
              icon="pi pi-plus-circle"
              class="p-button-lg p-button-warning p-4"
              @click="addNeedles()"
            />
          </div>
        </div>
        <div v-if="paymentMethodType == 'Tip'" class="content-height">
          <div class="transactions">
            <h5>
              <i class="pi pi-briefcase py-icon-color" aria-hidden="true"></i>
              Tip
            </h5>
          </div>
          <div class="col-md-12 p-0">
            <Button
              label="Add Tip"
              icon="pi pi-plus-circle"
              class="p-button-lg p-button-warning p-4"
              @click="addTip()"
            />
          </div>
        </div>
        <div v-if="paymentMethodType == 'Cash'" class="content-height">
          <div class="transactions">
            <h5>
              <i class="pi pi-money-bill py-icon-color" aria-hidden="true"></i>
              Add Tendered Cash
            </h5>
          </div>
          <div class="col-md-12 p-0">
            <Button
              label="Add Cash"
              icon="pi pi-money-bill"
              class="p-button-lg p-button-warning p-4"
              @click="addCashAmount()"
            />
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-12">
        <div class="content-height">
          <div class="transactions">
            <h5 class="">
              <i class="pi pi-money-bill py-icon-color" aria-hidden="true"></i>
              Payment Methods
            </h5>
            <table
              id="customer_payment_method_manual"
              class="table table-bordered table-striped py-list-collections table-hover"
            >
              <tr v-for="(item, index) in paymentList" :key="item">
                <template v-if="item.paymentType != 'Tip'">
                  <td class="text-left">{{ item.paymentType }}</td>
                  <td class="text-left">
                    $<span id="history_total_cash">{{
                      fixLength(item.transTotalAmount)
                    }}</span>
                    <span
                      @click="deletePayment(index, item)"
                      class="payment_cross"
                      ><i class="pi pi-times"></i
                    ></span>
                  </td>
                </template>
              </tr>
              <tr>
                <td style="background-color: green; color: #fff">Total</td>
                <td
                  class="text-left"
                  style="background-color: green; color: #fff"
                >
                  $<b>{{ totalPaymentsReceived().toFixed(2) }}</b>
                </td>
              </tr>
            </table>
          </div>
          <Button
            label="Done"
            icon="pi pi-check-circle"
            class="p-button-lg p-button-primary p-4"
            @click="confirmPayments()"
            :disabled="restriction == 'Yes' && paymentAction.amountLeft > 0"
          />
        </div>
      </div>
    </div>
  </Dialog>
  <Dialog
        v-model:visible="paymentCancelDialog"
        :style="{ width: '600px' }"
        header="Confirm">
        <div class="confirmation-content">
          <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
          <span>Are you sure to cancel ? You will lost the payments of amount <b> ${{ totalPaymentsReceived().toFixed(2) }}</b> </span>
        </div>
        <template #footer>
          <Button
            label="No"
            icon="pi pi-times"
            class="p-button-text"
            @click="cancelPaymentConfirm"
          />
          <Button
            label="Yes"
            icon="pi pi-check"
            class="p-button-danger"
            @click="closePaymentScreen"
          />
        </template>
      </Dialog>
      <Dialog
        v-model:visible="paymentConfirmDialog"
        :style="{ width: '600px' }"
        header="Confirm">
        <div class="confirmation-content">
          <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
          <span>Are you sure to continue this invoice. The remaining balance for this invoice is <b> ${{ paymentAction.amountLeft.toFixed(2) }}</b> ? </span>
        </div>
        <template #footer>
          <Button
            label="No"
            icon="pi pi-times"
            class="p-button-text"
            @click="paymentConfirmDialog = false"
          />
          <Button
            label="Yes"
            icon="pi pi-check"
            class="p-button-danger"
            @click="emitPayments()"
          />
        </template>
      </Dialog>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";
import Toaster from "../helpers/Toaster";
import PaymentService from "../service/PaymentService";
import { camelCase } from "lodash";
import { useStore } from "../store";

interface PaymentListType {
  paymentType: string;
  accountNo: string;
  terminalId: string;
  authCode: string;
  transId: string;
  transStatus: string;
  transType: string;
  transDate: string;
  transTime: string;
  transAmount: number;
  transTotalAmount: number;
  transRef: string;
  entryMode: string;
  hostResponse: string;
  giftCardRef: string;
  cardBalance: string;
  tendered: number;
  change: number;
  roundOff: number;
}

@Options({
  props: {
    receiptDetail: Object,
  },
  watch: {
    receiptDetail(obj) {
      this.paymentDialog = obj.dialogStatus;
      this.closeConfirmation = obj.closeConfirmation;
      this.totalBill = this.fixLength(obj.totalBill);
      this.paymentAction.amountLeft = this.fixLength(obj.totalBill);
      this.itemSource = obj.itemSource;
      this.restriction = obj.restriction;
      this.customerID = obj.customerID;
      this.customerName = obj.customerName;
      this.paymentAction.needlePoints = obj.needlePoints;
      this.dialogTilte = obj.dialogTilte + " for Customer " + this.customerName;
    },
  },
  emits: ["closePaymentScreenEvent","getProceededPaymentsEvent"],
})
export default class PaymentScreen extends Vue {
  private customerID;
  private store = useStore();
  private customerName;
  private acccountNo = "";
  private cardType = "";
  private paymentService;
  private paymentDialog = false;
  private paymentConfirmDialog = false;
  private closeConfirmation = false;
  private paymentCancelDialog = false;
  private totalBill = 0;
  private itemSource = "";
  private transStatus = "000";
  private toast;
  private restriction = "";
  private paymentMethodType = "Needle";
  private paymentAction = {
    amountLeft: 0,
    tendered: "0",
    change: 0,
    needlePoints: 0,
    roundOff: 0,
  };

  private paymentList: PaymentListType[] = [];

  created() {
    this.paymentService = new PaymentService();
    this.toast = new Toaster();
  }

  get progressBar() {
    return this.store.getters.getProgressBar;
  }

  paymentMethod(method) {
    this.paymentMethodType = method;
    this.pennyRounding(method);
  }

  closePaymentScreen()
  {
    this.paymentList = [];
    this.$emit("closePaymentScreenEvent");
    this.paymentCancelDialog = false;
  }

  cancelPaymentConfirm()
  {
    this.paymentDialog = true;
    this.paymentCancelDialog = false;
  }

  confirmPaymentCancel() {
    if(this.closeConfirmation == false)
    {
      this.paymentCancelDialog = true;
    }
  }

  amountNumpad(num) {
    num = String(num);

    if (num == "del") {
      this.paymentAction.tendered = "0";
      this.paymentAction.change = 0;
    } else {
      if (
        this.paymentAction.amountLeft > 0 ||
        this.paymentMethodType == "Tip"
      ) {
        const decimalPoint = this.retrDec(this.paymentAction.tendered);

        if (decimalPoint >= 2) {
          this.toast.showWarning(
            "You cannot enter more then two decimal points"
          );
        } else {
          if (this.paymentAction.tendered == "0") {
            this.paymentAction.tendered = num;
          } else {
            const current = this.paymentAction.tendered;
            this.paymentAction.tendered = current + num;
          }
        }
      } else {
        this.toast.showWarning("Invalid Amount must be greater then zero");
      }
    }
  }

  exactAmount() {
    this.paymentAction.tendered = String(this.paymentAction.amountLeft);
  }

  retrDec(num) {
    return (num.split(".")[1] || []).length;
  }

  fixLength(totalBill) {
    const amount = Number(totalBill.toFixed(2));
    return amount;
  }

  totalPaymentsReceived() {
    let total = 0;

    this.paymentList.forEach((e) => {
      if (e.paymentType != "Tip") {
        total = total + e.transTotalAmount;
      }
    });

    return this.fixLength(total);
  }

  addCashAmount() {
    const tendered = Number(this.paymentAction.tendered);
    if (tendered == 0) {
      this.toast.showError("Please enter amount greater then zero");
    } else {
      this.changeAmount();

      const payableAmount = Number(this.paymentAction.tendered);

      this.paymentList.push({
        paymentType: "Cash",
        accountNo: "",
        transTotalAmount: payableAmount,
        terminalId: "Manual",
        authCode: "",
        hostResponse: "",
        transId: "",
        transStatus: this.transStatus,
        transType: this.itemSource,
        transDate: "",
        transTime: "",
        transAmount: payableAmount,
        transRef: "",
        entryMode: "",
        giftCardRef: "",
        cardBalance: "",
        tendered: tendered,
        change: this.paymentAction.change,
        roundOff: this.paymentAction.roundOff,
      });

      this.adjustTotalDue();
      this.toast.showSuccess("Cash added successfully");
      this.pennyRounding(this.paymentMethodType);
    }
  }

  addManualAmount() {
    if (this.acccountNo == "" || this.cardType == "") {
      this.toast.showError("Please choose Card Type and Card No");
    } else {
      const tendered = Number(this.paymentAction.tendered);
      if (tendered == 0) {
        this.toast.showError("Please enter amount greater then zero");
      } else {
        this.changeAmount();

        const payableAmount = Number(this.paymentAction.tendered);

        this.paymentList.push({
          paymentType: this.cardType,
          accountNo: this.acccountNo,
          transTotalAmount: payableAmount,
          terminalId: "Manual",
          authCode: "",
          hostResponse: "",
          transId: "",
          transStatus: this.transStatus,
          transType: this.itemSource,
          transDate: "",
          transTime: "",
          transAmount: payableAmount,
          transRef: "",
          entryMode: "",
          giftCardRef: "",
          cardBalance: "",
          tendered: tendered,
          change: this.paymentAction.change,
          roundOff: 0,
        });

        this.adjustTotalDue();
        this.toast.showSuccess(this.cardType + " Payment added successfully");
        this.acccountNo = "";
      }
    }
  }

  addNeedles() {
    const tendered = Number(this.paymentAction.tendered);
    const usedNeedle = this.sumNeedlesPayment();
    const withOutTen = this.fixLength(usedNeedle - tendered);

    if (tendered == 0) {
      this.toast.showError("Please enter amount greater then zero");
    } else if (usedNeedle > this.paymentAction.needlePoints && (this.itemSource == 'Checkout' || this.itemSource == 'Checkin')) {
      this.toast.showError(
        "Sorry Not enough needle points to be used. You have already used  amount of $" +
          withOutTen
      );
    } else {
      this.changeAmount();

      const payableAmount = Number(this.paymentAction.tendered);

      this.paymentList.push({
        paymentType: "Needle",
        accountNo: "",
        transTotalAmount: payableAmount,
        terminalId: "Manual",
        authCode: "",
        hostResponse: "",
        transId: "",
        transStatus: this.transStatus,
        transType: this.itemSource,
        transDate: "",
        transTime: "",
        transAmount: payableAmount,
        transRef: "",
        entryMode: "",
        giftCardRef: "",
        cardBalance: "",
        tendered: tendered,
        change: this.paymentAction.change,
        roundOff: 0,
      });

      this.adjustTotalDue();
      this.toast.showSuccess("Needles added successfully");
    }
  }

  addTip() {
    const tendered = Number(this.paymentAction.tendered);

    this.paymentList.push({
      paymentType: "Tip",
      accountNo: "",
      transTotalAmount: tendered,
      terminalId: "Manual",
      authCode: "",
      hostResponse: "",
      transId: "",
      transStatus: this.transStatus,
      transType: this.itemSource,
      transDate: "",
      transTime: "",
      transAmount: tendered,
      transRef: "",
      entryMode: "",
      giftCardRef: "",
      cardBalance: "",
      tendered: 0,
      change: 0,
      roundOff: 0,
    });

    this.paymentAction.tendered = "0";
    this.toast.showSuccess("Tip added successfully");
    this.paymentMethodType = "Card";
  }

  adjustTotalDue() {
    const tendered = Number(this.paymentAction.tendered);
    const amountLeft = this.paymentAction.amountLeft;
    const afterTendered = amountLeft - tendered;

    this.paymentAction.amountLeft = this.fixLength(afterTendered);
    this.paymentAction.tendered = "0";
  }

  changeAmount() {
    const amountLeft = this.paymentAction.amountLeft;
    const tendered = Number(this.paymentAction.tendered);

    const balance = tendered - amountLeft;

    if (balance > 0) {
      this.paymentAction.tendered = String(amountLeft);
      this.paymentAction.change = this.fixLength(balance);
    } else {
      this.paymentAction.change = 0;
    }
  }

  deletePayment(index, obj) {
    const amountRestore = obj.transTotalAmount + obj.roundOff;

    this.paymentAction.amountLeft = this.fixLength(
      this.paymentAction.amountLeft + amountRestore
    );
    this.paymentList.splice(index, 1);
    this.toast.showSuccess(
      "Amount of $" + this.fixLength(amountRestore) + " removed successfully"
    );
  }

  sumNeedlesPayment() {
    let total = 0;

    const tendered = Number(this.paymentAction.tendered);

    this.paymentList.forEach((e) => {
      if (e.paymentType == "Needle") {
        total = total + e.transTotalAmount;
      }
    });

    return total + tendered;
  }

  clearAccountNo() {
    this.acccountNo = "";
  }

  termianlPayment() {
    if (this.itemSource != "") {
      const tendered = Number(this.paymentAction.tendered);

      this.changeAmount();

      const payableAmount = Number(this.paymentAction.tendered);

      this.paymentService
        .terminalRequest(this.customerName, payableAmount, this.itemSource)
        .then((res) => {
          const response = this.camelizeKeys(res);

          if (response.status == "request_cancel") {
            this.toast.showSuccess("Request cancelled Successfully");
          } else {
            if (
              response.payment.paymentType != "" &&
              response.payment.paymentType
            ) {
              this.paymentList.push({
                paymentType: response.payment.paymentType,
                accountNo: response.payment.accountNo,
                transTotalAmount: response.payment.transTotalAmount,
                terminalId: response.payment.terminalId,
                authCode: response.payment.authCode,
                hostResponse: response.payment.hostResponse,
                transId: response.payment.transId,
                transStatus: response.payment.transStatus,
                transType: response.payment.transType,
                transDate: response.payment.transDate,
                transTime: response.payment.transTime,
                transAmount: response.payment.transAmount,
                transRef: response.payment.transRef,
                entryMode: response.payment.entryMode,
                giftCardRef: response.payment.giftCardRef,
                cardBalance: response.payment.cardBalance,
                tendered: tendered,
                change: this.paymentAction.change,
                roundOff: 0,
              });

              this.adjustTotalDue();
              this.toast.showSuccess(
                response.payment.paymentType + " payment added Successfully"
              );
            } else {
              this.toast.showError("Invalid Payment Type");
            }

            if (response.tip.paymentType != "" && response.tip.paymentType) {
              this.paymentList.push(response.tip);
              this.toast.showSuccess(
                response.tip.paymentType + " payment added Successfully"
              );
            }
          }
        });
    } else {
      this.toast.showError(
        "Unable to find the source.Try to close and re open payment screen"
      );
    }
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  pennyRounding(method) {
    if (method == "Cash") {
      const amountLeft = this.paymentAction.amountLeft;
      const roundNum = Math.round(amountLeft / 0.05) * 0.05;
      const roundOff = amountLeft - roundNum;
      this.paymentAction.roundOff = this.fixLength(roundOff);
      this.paymentAction.amountLeft = this.fixLength(roundNum);
    } else {
      this.paymentAction.amountLeft =
        this.paymentAction.amountLeft +
        this.fixLength(this.paymentAction.roundOff);
      this.paymentAction.roundOff = 0;
    }
  }

  confirmPayments()
  {
    //restriction == 'Yes'
    if(this.paymentAction.amountLeft > 0 && this.restriction == 'No')
    {
      this.paymentConfirmDialog = true;
    }
    else
    {
      this.emitPayments();
    }
  }

  emitPayments()
  {
    this.paymentConfirmDialog = false;
    this.$emit('getProceededPaymentsEvent',this.paymentList);
  }
}
</script>

<style scoped>
.py-icon-color {
  color: orangered;
}

.pay-size-bx {
  background-color: #fff;
  border: 1px solid #eee;
  margin-right: 5px;
  display: inline-block;
}

.py-span {
  font-size: 16px;
  padding: 2px;
  width: 95px;
  border-radius: 5px;
  color: #fff;
  background-color: #1463a5;
  margin: 0px;
  text-align: center;
  box-shadow: 0px 0px 5px 2px #ccc;
}

.py-span img {
  width: 100%;
  display: block;
}

.py-description {
  border: 1px dotted #ccc;
  box-shadow: 0px 0px 10px 2px #eee;
  border-radius: 5px;
  padding: 2px 2px;
  margin: 1px;
}

.btn-numpad {
  width: 7.5vw;
  height: 10.8vh;
  background-color: #004c97;
  border-radius: 5px;
  font-size: 25px;
  color: #fff;
  border: 1px solid #fff;
}

.btn-numpad-danger {
  width: 7.5vw;
  height: 10.8vh;
  background-color: #c00;
  border-radius: 5px;
  font-size: 25px;
  border: 1px solid #fff;
  color: #fff;
}

.transactions {
  background-color: #fff;
  height: 48vh;
  min-height: 48vh;
  overflow-y: scroll;
}

.transactions-card-manual {
  background-color: #fff;
  height: 43vh;
  min-height: 43vh;
  overflow-y: scroll;
}

.transactions-card-manual td {
  padding: 2px;
}

.transactions td {
  padding: 2px;
}

.payment_cross {
  float: right;
  color: #c00;
}
.payment_cross:hover {
  cursor: pointer;
}

.content-height {
  margin: 8px 0px 15px 0px;
}

.py-customize-bx {
  height: 60px;
  background: transparent;
  margin: 0px;
  padding: 0px;
  font-size: 45px;
  border: none;
}

.py-balance-due {
  color: #c00;
}
</style>
