
import { Vue,Options } from 'vue-class-component';
import { useStore } from "../store";
import SearchTransaction from "../service/SearchTransaction";
import Toaster from "../helpers/Toaster";



@Options({
	components: {},
	emits: ["searchReceiptEvent"],
  })


export default class SearchTransactions extends Vue {

  private store = useStore();
  private searchService;
  private toast;
  private orderLists = [];

    //DEFAULT METHOD OF TYPE SCRIPT
    //CALLING WHENEVER COMPONENT LOADS
    created()
    {
        this.searchService = new SearchTransaction();
        this.toast = new Toaster();
    }

    private searchReceipt = {
        receiptNO: '',
        searchFilter: '',
        dateFrom: '',
        dateTo: '',
    }

    searchReceiptDetails()
    {
        this.searchService.searchItem(this.searchReceipt).then((data) => {
            this.$emit("searchReceiptEvent", data);
        });
    }
}
