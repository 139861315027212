
import { Vue, Options } from "vue-class-component";
import Toaster from "../helpers/Toaster";
import PaymentService from "../service/PaymentService";
import { camelCase } from "lodash";
import { useStore } from "../store";

interface PaymentListType {
  paymentType: string;
  accountNo: string;
  terminalId: string;
  authCode: string;
  transId: string;
  transStatus: string;
  transType: string;
  transDate: string;
  transTime: string;
  transAmount: number;
  transTotalAmount: number;
  transRef: string;
  entryMode: string;
  hostResponse: string;
  giftCardRef: string;
  cardBalance: string;
  tendered: number;
  change: number;
  roundOff: number;
}

@Options({
  props: {
    receiptDetail: Object,
  },
  watch: {
    receiptDetail(obj) {
      this.paymentDialog = obj.dialogStatus;
      this.closeConfirmation = obj.closeConfirmation;
      this.totalBill = this.fixLength(obj.totalBill);
      this.paymentAction.amountLeft = this.fixLength(obj.totalBill);
      this.itemSource = obj.itemSource;
      this.restriction = obj.restriction;
      this.customerID = obj.customerID;
      this.customerName = obj.customerName;
      this.paymentAction.needlePoints = obj.needlePoints;
      this.dialogTilte = obj.dialogTilte + " for Customer " + this.customerName;
    },
  },
  emits: ["closePaymentScreenEvent","getProceededPaymentsEvent"],
})
export default class PaymentScreen extends Vue {
  private customerID;
  private store = useStore();
  private customerName;
  private acccountNo = "";
  private cardType = "";
  private paymentService;
  private paymentDialog = false;
  private paymentConfirmDialog = false;
  private closeConfirmation = false;
  private paymentCancelDialog = false;
  private totalBill = 0;
  private itemSource = "";
  private transStatus = "000";
  private toast;
  private restriction = "";
  private paymentMethodType = "Needle";
  private paymentAction = {
    amountLeft: 0,
    tendered: "0",
    change: 0,
    needlePoints: 0,
    roundOff: 0,
  };

  private paymentList: PaymentListType[] = [];

  created() {
    this.paymentService = new PaymentService();
    this.toast = new Toaster();
  }

  get progressBar() {
    return this.store.getters.getProgressBar;
  }

  paymentMethod(method) {
    this.paymentMethodType = method;
    this.pennyRounding(method);
  }

  closePaymentScreen()
  {
    this.paymentList = [];
    this.$emit("closePaymentScreenEvent");
    this.paymentCancelDialog = false;
  }

  cancelPaymentConfirm()
  {
    this.paymentDialog = true;
    this.paymentCancelDialog = false;
  }

  confirmPaymentCancel() {
    if(this.closeConfirmation == false)
    {
      this.paymentCancelDialog = true;
    }
  }

  amountNumpad(num) {
    num = String(num);

    if (num == "del") {
      this.paymentAction.tendered = "0";
      this.paymentAction.change = 0;
    } else {
      if (
        this.paymentAction.amountLeft > 0 ||
        this.paymentMethodType == "Tip"
      ) {
        const decimalPoint = this.retrDec(this.paymentAction.tendered);

        if (decimalPoint >= 2) {
          this.toast.showWarning(
            "You cannot enter more then two decimal points"
          );
        } else {
          if (this.paymentAction.tendered == "0") {
            this.paymentAction.tendered = num;
          } else {
            const current = this.paymentAction.tendered;
            this.paymentAction.tendered = current + num;
          }
        }
      } else {
        this.toast.showWarning("Invalid Amount must be greater then zero");
      }
    }
  }

  exactAmount() {
    this.paymentAction.tendered = String(this.paymentAction.amountLeft);
  }

  retrDec(num) {
    return (num.split(".")[1] || []).length;
  }

  fixLength(totalBill) {
    const amount = Number(totalBill.toFixed(2));
    return amount;
  }

  totalPaymentsReceived() {
    let total = 0;

    this.paymentList.forEach((e) => {
      if (e.paymentType != "Tip") {
        total = total + e.transTotalAmount;
      }
    });

    return this.fixLength(total);
  }

  addCashAmount() {
    const tendered = Number(this.paymentAction.tendered);
    if (tendered == 0) {
      this.toast.showError("Please enter amount greater then zero");
    } else {
      this.changeAmount();

      const payableAmount = Number(this.paymentAction.tendered);

      this.paymentList.push({
        paymentType: "Cash",
        accountNo: "",
        transTotalAmount: payableAmount,
        terminalId: "Manual",
        authCode: "",
        hostResponse: "",
        transId: "",
        transStatus: this.transStatus,
        transType: this.itemSource,
        transDate: "",
        transTime: "",
        transAmount: payableAmount,
        transRef: "",
        entryMode: "",
        giftCardRef: "",
        cardBalance: "",
        tendered: tendered,
        change: this.paymentAction.change,
        roundOff: this.paymentAction.roundOff,
      });

      this.adjustTotalDue();
      this.toast.showSuccess("Cash added successfully");
      this.pennyRounding(this.paymentMethodType);
    }
  }

  addManualAmount() {
    if (this.acccountNo == "" || this.cardType == "") {
      this.toast.showError("Please choose Card Type and Card No");
    } else {
      const tendered = Number(this.paymentAction.tendered);
      if (tendered == 0) {
        this.toast.showError("Please enter amount greater then zero");
      } else {
        this.changeAmount();

        const payableAmount = Number(this.paymentAction.tendered);

        this.paymentList.push({
          paymentType: this.cardType,
          accountNo: this.acccountNo,
          transTotalAmount: payableAmount,
          terminalId: "Manual",
          authCode: "",
          hostResponse: "",
          transId: "",
          transStatus: this.transStatus,
          transType: this.itemSource,
          transDate: "",
          transTime: "",
          transAmount: payableAmount,
          transRef: "",
          entryMode: "",
          giftCardRef: "",
          cardBalance: "",
          tendered: tendered,
          change: this.paymentAction.change,
          roundOff: 0,
        });

        this.adjustTotalDue();
        this.toast.showSuccess(this.cardType + " Payment added successfully");
        this.acccountNo = "";
      }
    }
  }

  addNeedles() {
    const tendered = Number(this.paymentAction.tendered);
    const usedNeedle = this.sumNeedlesPayment();
    const withOutTen = this.fixLength(usedNeedle - tendered);

    if (tendered == 0) {
      this.toast.showError("Please enter amount greater then zero");
    } else if (usedNeedle > this.paymentAction.needlePoints && (this.itemSource == 'Checkout' || this.itemSource == 'Checkin')) {
      this.toast.showError(
        "Sorry Not enough needle points to be used. You have already used  amount of $" +
          withOutTen
      );
    } else {
      this.changeAmount();

      const payableAmount = Number(this.paymentAction.tendered);

      this.paymentList.push({
        paymentType: "Needle",
        accountNo: "",
        transTotalAmount: payableAmount,
        terminalId: "Manual",
        authCode: "",
        hostResponse: "",
        transId: "",
        transStatus: this.transStatus,
        transType: this.itemSource,
        transDate: "",
        transTime: "",
        transAmount: payableAmount,
        transRef: "",
        entryMode: "",
        giftCardRef: "",
        cardBalance: "",
        tendered: tendered,
        change: this.paymentAction.change,
        roundOff: 0,
      });

      this.adjustTotalDue();
      this.toast.showSuccess("Needles added successfully");
    }
  }

  addTip() {
    const tendered = Number(this.paymentAction.tendered);

    this.paymentList.push({
      paymentType: "Tip",
      accountNo: "",
      transTotalAmount: tendered,
      terminalId: "Manual",
      authCode: "",
      hostResponse: "",
      transId: "",
      transStatus: this.transStatus,
      transType: this.itemSource,
      transDate: "",
      transTime: "",
      transAmount: tendered,
      transRef: "",
      entryMode: "",
      giftCardRef: "",
      cardBalance: "",
      tendered: 0,
      change: 0,
      roundOff: 0,
    });

    this.paymentAction.tendered = "0";
    this.toast.showSuccess("Tip added successfully");
    this.paymentMethodType = "Card";
  }

  adjustTotalDue() {
    const tendered = Number(this.paymentAction.tendered);
    const amountLeft = this.paymentAction.amountLeft;
    const afterTendered = amountLeft - tendered;

    this.paymentAction.amountLeft = this.fixLength(afterTendered);
    this.paymentAction.tendered = "0";
  }

  changeAmount() {
    const amountLeft = this.paymentAction.amountLeft;
    const tendered = Number(this.paymentAction.tendered);

    const balance = tendered - amountLeft;

    if (balance > 0) {
      this.paymentAction.tendered = String(amountLeft);
      this.paymentAction.change = this.fixLength(balance);
    } else {
      this.paymentAction.change = 0;
    }
  }

  deletePayment(index, obj) {
    const amountRestore = obj.transTotalAmount + obj.roundOff;

    this.paymentAction.amountLeft = this.fixLength(
      this.paymentAction.amountLeft + amountRestore
    );
    this.paymentList.splice(index, 1);
    this.toast.showSuccess(
      "Amount of $" + this.fixLength(amountRestore) + " removed successfully"
    );
  }

  sumNeedlesPayment() {
    let total = 0;

    const tendered = Number(this.paymentAction.tendered);

    this.paymentList.forEach((e) => {
      if (e.paymentType == "Needle") {
        total = total + e.transTotalAmount;
      }
    });

    return total + tendered;
  }

  clearAccountNo() {
    this.acccountNo = "";
  }

  termianlPayment() {
    if (this.itemSource != "") {
      const tendered = Number(this.paymentAction.tendered);

      this.changeAmount();

      const payableAmount = Number(this.paymentAction.tendered);

      this.paymentService
        .terminalRequest(this.customerName, payableAmount, this.itemSource)
        .then((res) => {
          const response = this.camelizeKeys(res);

          if (response.status == "request_cancel") {
            this.toast.showSuccess("Request cancelled Successfully");
          } else {
            if (
              response.payment.paymentType != "" &&
              response.payment.paymentType
            ) {
              this.paymentList.push({
                paymentType: response.payment.paymentType,
                accountNo: response.payment.accountNo,
                transTotalAmount: response.payment.transTotalAmount,
                terminalId: response.payment.terminalId,
                authCode: response.payment.authCode,
                hostResponse: response.payment.hostResponse,
                transId: response.payment.transId,
                transStatus: response.payment.transStatus,
                transType: response.payment.transType,
                transDate: response.payment.transDate,
                transTime: response.payment.transTime,
                transAmount: response.payment.transAmount,
                transRef: response.payment.transRef,
                entryMode: response.payment.entryMode,
                giftCardRef: response.payment.giftCardRef,
                cardBalance: response.payment.cardBalance,
                tendered: tendered,
                change: this.paymentAction.change,
                roundOff: 0,
              });

              this.adjustTotalDue();
              this.toast.showSuccess(
                response.payment.paymentType + " payment added Successfully"
              );
            } else {
              this.toast.showError("Invalid Payment Type");
            }

            if (response.tip.paymentType != "" && response.tip.paymentType) {
              this.paymentList.push(response.tip);
              this.toast.showSuccess(
                response.tip.paymentType + " payment added Successfully"
              );
            }
          }
        });
    } else {
      this.toast.showError(
        "Unable to find the source.Try to close and re open payment screen"
      );
    }
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  pennyRounding(method) {
    if (method == "Cash") {
      const amountLeft = this.paymentAction.amountLeft;
      const roundNum = Math.round(amountLeft / 0.05) * 0.05;
      const roundOff = amountLeft - roundNum;
      this.paymentAction.roundOff = this.fixLength(roundOff);
      this.paymentAction.amountLeft = this.fixLength(roundNum);
    } else {
      this.paymentAction.amountLeft =
        this.paymentAction.amountLeft +
        this.fixLength(this.paymentAction.roundOff);
      this.paymentAction.roundOff = 0;
    }
  }

  confirmPayments()
  {
    //restriction == 'Yes'
    if(this.paymentAction.amountLeft > 0 && this.restriction == 'No')
    {
      this.paymentConfirmDialog = true;
    }
    else
    {
      this.emitPayments();
    }
  }

  emitPayments()
  {
    this.paymentConfirmDialog = false;
    this.$emit('getProceededPaymentsEvent',this.paymentList);
  }
}
