
import { Options, Vue } from "vue-class-component";
import Toaster from "../helpers/Toaster";
import Backrooom from "../service/Backrooom";
import { camelCase } from "lodash";
import moment from "moment";

@Options({
  props: {
    receiptDetail: Object,
  },
  watch: {
    receiptDetail(obj) {
      if (obj.status) {
        this.receiptID = obj.receiptID;
        this.openDialog();
      }
    },
  },
  emits: ["updateReceiptStatus"],
})
export default class PreviewReceipt extends Vue {
  private toast;
  private submitted = false;
  private productDialog = false;
  private dialogTitle;
  private receiptID;
  private backroomService;
  private statements = [];
  private receiptData = {
    address: "",
    allNeedles: "",
    balance: "",
    branchCode: "",
    branchName: "",
    currentNeedles: "",
    customer: "",
    customerId: "",
    customerTel: "",
    dateTime: "",
    defectReason: "",
    discountAmt: "",
    discountMethod: "",
    empId: "",
    employeeName: "",
    invPayStatus: "",
    itemsDetails: [
      {
        checkRedo: "",
        checkStatus: "",
        completedDate: "",
        completedTime: "",
        createdEmpId: "",
        date: "",
        description: "",
        discount: "",
        discountId: "",
        discountMethod: "",
        discountName: "",
        dueDate: "",
        dueTime: "",
        express: "",
        expressAmt: "",
        expressNotification: "",
        id: "",
        invId: "",
        itemList: [
          {
            itemDetailCharges: "",
            itemId: "",
            itemName: "",
            itemQty: "",
            price: "",
            salesItemId: "",
            tag3: "",
          },
        ],
        qty: "",
        serviceId: "",
        serviceName: "",
        startDate: "",
        startTime: "",
        storeId: "",
        tag1: "",
        tag2: "",
        tax: "",
        totalBill: 0,
        upchargeCharges: "",
        upchargeChargesPer: "",
        workingStatus: "",
      },
    ],
    itemsTotal: "",
    memo: "",
    paymentMethod: "",
    pricelist: "",
    prodDate: "",
    readyOn: "",
    statements: [
      {
        branchId: "",
        createdDate: "",
        priority: "",
        receiptContent: "",
        receiptHeading: "",
        receiptId: "",
        status: "",
        type: "",
      },
    ],
    receipt: "",
    receiptNo: "",
    tax: "",
    taxCode: "",
    taxName: "",
    taxNumber: "",
    telephone: "",
    total: "",
    totalChange: "",
    totalPaid: "",
    totalTendered: "",
  };

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.toast = new Toaster();
    this.backroomService = new Backrooom();
  }

  mounted() {
    //asdsd
  }

  closeDialogBox() {
    this.submitted = false;
    this.productDialog = false;
    //this.clearItem();
    this.$emit("updateReceiptStatus");
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    //this.clearItem();
    this.submitted = false;
    this.productDialog = true;
    this.dialogTitle = "Preview Receipt";
    this.fetchReceiptData();
  }

  fetchReceiptData() {
    this.backroomService.customerReceipt(this.receiptID).then((data) => {
      const receiptInfo = this.camelizeKeys(data.general_info);
      this.receiptData = receiptInfo;
    });
  }

  calculateSubtotal() {
    let subTotal = 0;
    this.receiptData.itemsDetails.filter((e) => {
      subTotal = subTotal + e.totalBill;
    });
    return this.formatAmount(subTotal);
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

 formatAmount(value) {
    value = Number(value);

    let balance = 0;

    if (value != "") {
      balance = value.toFixed(2);
    }
    return balance;
  }

  formatDate(value) {
    if (value) {
      return moment(String(value)).format("DD-MM-YYYY");
    }
  }

  formatTime(value) {
    if (value) {
      const time = moment.duration(value);
      return moment(String(time), "HH:mm").format("hh:mm A");
    }
  }
}
