
import { Vue, Options } from "vue-class-component";
import { useStore } from "../store";
import ReceiptPayement from "../service/ReceiptPayement";
import moment from "moment";
import { camelCase } from "lodash";
import Toaster from "../helpers/Toaster";


interface PaymentListType {
  paymentType: string;
  accountNo: string;
  terminalId: string;
  authCode: string;
  transId: string;
  transStatus: string;
  transType: string;
  transDate: string;
  transTime: string;
  transAmount: number;
  transTotalAmount: number;
  transRef: string;
  entryMode: string;
  hostResponse: string;
  giftCardRef: string;
  cardBalance: string;
  tendered: number;
  change: number;
  roundOff: number;
}


@Options({
  components: {},
})
export default class ReceiptPayment extends Vue {
  private store = useStore();
  private txnService;
  private toast;
  private receiptPayments: PaymentListType [] = [];


  created() {
    this.txnService = new ReceiptPayement();
    this.toast = new Toaster();
  }

  get receiptID() {
    return this.store.getters.getReceiptID;
  }

  mounted() {
    this.getReceiptPayments();
  }

  getReceiptPayments() {
    this.txnService.getPayments(this.receiptID).then((data) => {
      const receipt = this.camelizeKeys(data);
      this.receiptPayments = receipt.invoicePayment;
    });
  }

  formatDate(value) {
    if (value) {
      return moment(String(value)).format("DD-MM-YYYY");
    }
  }

  formatTime(value) {
    if (value) {
      const time = moment.duration(value);
      return moment(String(time), "HH:mm").format("hh:mm A");
    }
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  fixLength(amount) {
    amount = Number(amount);

    if (amount != " ") {
      amount = amount.toFixed(2);
    }

    return amount;
  }

  totalAmount()
  {

    let totalBalance = 0;

     this.receiptPayments.forEach(e => {
         totalBalance = totalBalance + e.transTotalAmount;
     });

    return totalBalance.toFixed(2);
  }
}
