<template>
  <div class="row">
    <div class="col-md-12 p-0">
      <h5 class="checkout-heading">
        <i class="pi pi-file-o"></i> Order ID : 546546
      </h5>
      <h5 class="mt-3 mb-3 text-center">Redo Option</h5>
      <div class="col-md-12">
        <div class="form-group">
          <label> Employee PIN </label>
          <input
            type="password"
            v-model.trim="redoList.associateID"
            class="form-control"
            :class="{ 'p-invalid': submitted && !redoList.associateID }"
          />
          <small class="p-invalid" v-if="submitted && !redoList.associateID"
            >PIN is required.</small
          >
        </div>
        <div class="form-group">
          <label> Due Date </label>
          <input
            type="date"
            v-model.trim="redoList.dueDate"
            class="form-control"
            :class="{ 'p-invalid': submitted && !redoList.dueDate }"
          />
          <small class="p-invalid" v-if="submitted && !redoList.dueDate"
            >Due Date is required.</small
          >
        </div>
        <div class="form-group">
          <label> Due Time </label>
          <input
            type="time"
            v-model.trim="redoList.dueTime"
            class="form-control"
            :class="{ 'p-invalid': submitted && !redoList.dueTime }"
          />
          <small class="p-invalid" v-if="submitted && !redoList.dueTime"
            >Due Time is required.</small
          >
        </div>
        <div class="form-group">
          <label
            >Please mention the redo reason for each service (if
            multiple)</label
          >
          <input
            type="text"
            v-model.trim="redoList.defectReason"
            class="form-control"
            :class="{ 'p-invalid': submitted && !redoList.defectReason }"
          />
          <small class="p-invalid" v-if="submitted && !redoList.defectReason"
            >Reason is required.</small
          >
        </div>
        <div class="form-group">
          <Button
            label="Redo Invoice"
            icon="pi pi-check"
            @click="saveRedo"
            class="p-button p-button-primary pull-right"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";
import { useStore } from "../store";
import Transaction from "../service/Transaction";
import Toaster from "../helpers/Toaster";

@Options({
  components: {},
  emits: ["updateRedoReceipt"],
})
export default class RedoReceipt extends Vue {
  private store = useStore();
  private txnService;
  private toast;
  private submitted = false;
  private redoList = {
    associateID: "",
    dueDate: "",
    dueTime: "",
    defectReason: "",
  };

  created() {
    this.txnService = new Transaction();
    this.toast = new Toaster();
  }

  get receiptID() {
    return this.store.getters.getReceiptID;
  }

  saveRedo() {
    this.submitted = true;

    if (
      this.redoList.associateID != "" &&
      this.redoList.dueDate != "" &&
      this.redoList.dueTime != "" &&
      this.redoList.defectReason != ""
    ) {
      this.submitted = false;
      this.$emit("updateRedoReceipt", this.redoList);
    } else {
      this.toast.showWarning("Please fill all the given fields");
    }
  }
}
</script>
