<template>
<div class="row">
 <h4 class="checkout-heading">
    <i class="pi pi-search"></i> Search Invoice
</h4>
<div class="col-md-12 ">
    <div class="form-group mt-4">
        <h4>Customer Name / Invoice No:</h4>
        <input type="text" placeholder='Cust Name OR INV NO OR Cell No' v-model="searchReceipt.receiptNO" class="form-control input-lg" />
    </div>
    <div class="form-group mt-4">
        <h4>Filter </h4>
        <select  name="filter_dates" v-model="searchReceipt.searchFilter" class="form-control">
            <option value="None">None</option>
            <option value="this_month">This Month</option>
            <option value="last_month">Last Month</option>
            <option value="last_three">Last Three Months</option>
            <option value="this_year">This Year</option>
        </select>
    </div>
    <h3>
        OR
    </h3>
    <div class="form-group mt-4">
        <h4>Date From:</h4>
        <input type="date" class="form-control input-lg" v-model="searchReceipt.dateFrom" />
    </div>
    <div class="form-group mt-4">
        <h4>Date To:</h4>
        <input type="date" class="form-control input-lg" v-model="searchReceipt.dateTo" />
    </div>
    <div class="form-group mt-4">
        <Button
            label="Search" icon="pi pi-search" @click="searchReceiptDetails"
            class="p-button-raised p-button-primary customClass p-p-3"
        />
    </div>
</div>
</div>
</template>

<script lang="ts">
import { Vue,Options } from 'vue-class-component';
import { useStore } from "../store";
import SearchTransaction from "../service/SearchTransaction";
import Toaster from "../helpers/Toaster";



@Options({
	components: {},
	emits: ["searchReceiptEvent"],
  })


export default class SearchTransactions extends Vue {

  private store = useStore();
  private searchService;
  private toast;
  private orderLists = [];

    //DEFAULT METHOD OF TYPE SCRIPT
    //CALLING WHENEVER COMPONENT LOADS
    created()
    {
        this.searchService = new SearchTransaction();
        this.toast = new Toaster();
    }

    private searchReceipt = {
        receiptNO: '',
        searchFilter: '',
        dateFrom: '',
        dateTo: '',
    }

    searchReceiptDetails()
    {
        this.searchService.searchItem(this.searchReceipt).then((data) => {
            this.$emit("searchReceiptEvent", data);
        });
    }
}
</script>

<style scoped>

</style>
