<template>
  <Dialog
    v-model:visible="dialogStatus"
    :style="{ width: '60vw' }"
    :maximizable="true"
    :closable="false"
    position="top"
    class="p-fluid"
  >
    <template #header>
      <h4 class="p-dialog-titlebar p-dialog-titlebar-icon">
        Store PIN Settings
      </h4>
    </template>
    <div class="p-formgrid p-grid">
      <div class="p-field p-col">
        <b> <i class="pi pi-key-large"></i> Change Store PIN</b>
      </div>
      <div class="p-field p-col">
        <b> <i class="pi pi-eye-large"></i> Show Store PIN</b>
      </div>
    </div>
    <div class="p-formgrid p-grid">
      <div class="p-field p-col">
        <div class="p-field p-row">
          <label for="StoreID">Store ID :</label>
          <InputNumber
            id="StoreID"
            v-model="storePinSettings.storeID"
            disabled
            :useGrouping="false"
            :class="{ 'p-invalid': submitted && !storePinSettings.storeID }"
            placeholder="e.g 0060"
          />
          <small class="p-invalid" v-if="submitted && !storePinSettings.storeID"
            >Store ID is required.</small
          >
        </div>
        <div class="p-field p-row">
          <label for="OldPIN">Old PIN :</label>
          <InputText
            v-model="storePinSettings.oldPin"
            :class="{ 'p-invalid': submitted && !storePinSettings.oldPin }"
          />
          <small class="p-invalid" v-if="submitted && !storePinSettings.oldPin"
            >Old Pin is required.</small
          >
        </div>
        <div class="p-field p-row">
          <label for="NewPIN">New PIN :</label>
          <InputText
            v-model="storePinSettings.newPin"
            :class="{ 'p-invalid': submitted && !storePinSettings.newPin }"
          />
          <small class="p-invalid" v-if="submitted && !storePinSettings.newPin"
            >New Pin is required.</small
          >
        </div>
        <div class="p-field p-row">
          <label for="ConfirmPIN">Confirm PIN :</label>
          <InputText
            v-model="storePinSettings.confirmPin"
            :class="{ 'p-invalid': submitted && !storePinSettings.confirmPin }"
          />
          <small
            class="p-invalid"
            v-if="submitted && !storePinSettings.confirmPin"
            >Confirm Pin is required.</small
          >
        </div>
        <div class="p-field p-row">
          <Button
            type="submit"
            label="Change PIN"
            @click="changeStorePin"
            class="p-button-raised p-button-primary"
          />
        </div>
      </div>
      <div class="p-field p-col">
        <div class="p-field p-row">
          <label for="StoreIDView">Store ID :</label>
          <InputText
            id="StoreIDView"
            v-model="storePinSettings.storeIDView"
            disabled
            :useGrouping="false"
            placeholder="e.g 0060"
          />
        </div>
        <div class="p-field p-row">
          <label for="StoreManagerPIN">Store Manager PIN :</label>
          <InputText
            v-model="storePinSettings.storeManagerPin"
            :class="{
              'p-invalid':
                submittedShowPin && !storePinSettings.storeManagerPin,
            }"
          />
          <small
            class="p-invalid"
            v-if="submittedShowPin && !storePinSettings.storeManagerPin"
            >Manager Pin is required.</small
          >
        </div>
        <div class="p-field p-row">
          <label for="YourPassword">Store PIN :</label>
          <InputText
            id="YourPassword"
            disabled
            v-model="storePinSettings.yourPassword"
          />
        </div>
        <div class="p-field p-row">
          <Button
            type="submit"
            label="Show PIN"
            @click="showStorePin"
            class="p-button-raised p-button-primary"
          />
        </div>
      </div>
    </div>
    <template #footer>
      <Button
        label="Close"
        icon="pi pi-times"
        class="p-button-text"
        @click="closeDialogBox"
      />
    </template>
  </Dialog>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Branch from "../service/Branch";
import Toaster from "../helpers/Toaster";

@Options({
  props: {
    pinDialogStatus: Boolean,
    storeCode: Number,
  },
  watch: {
    pinDialogStatus(val) {
      this.dialogStatus = val;
    },
    storeCode(val) {
      this.clearItems();
      this.storePinSettings.storeID = val;
      this.storePinSettings.storeIDView = val;
    },
  },
  emits: ["changeDialogStatus"],
})
export default class StorePin extends Vue {
  // private store = useStore();
  private dialogStatus = false;
  private storeID = 0;
  private showDialog;
  private toast;
  private branches;
  private submittedShowPin = false;
  private submitted = false;
  private storePinSettings = {
    storeID: 0,
    storeIDView: 0,
    oldPin: "",
    newPin: "",
    confirmPin: "",
    storeManagerPin: "",
    yourPassword: "",
  };

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.branches = new Branch();
    this.toast = new Toaster();
  }

  //USED TO CLEAR THE FIELDS
  clearItems() {
    this.storePinSettings = {
      storeID: 0,
      storeIDView: 0,
      oldPin: "",
      newPin: "",
      confirmPin: "",
      storeManagerPin: "",
      yourPassword: "",
    };
  }

  //SHOW STORE PIN
  changeStorePin(e) {
    e.preventDefault();

    this.submitted = true;

    if (this.storePinSettings.newPin == this.storePinSettings.confirmPin) {
      this.branches.changeStorePin(this.storePinSettings).then((res) => {
        //SHOW NOTIFICATION
        //CLOSE DIALOG
        this.toast.handleResponse(res);
      });
    } else {
      this.toast.showError("Invalid new pin & confirm pin are not matching");
    }
  }

  //SHOW STORE PIN
  showStorePin() {
    this.submittedShowPin = true;
    this.branches.showStorePin(this.storePinSettings).then((res) => {
      if (res != "") {
        this.storePinSettings.yourPassword = res;
      }
    });
  }

  closeDialogBox() {
    this.clearItems();
    this.$emit("changeDialogStatus");
  }
}
</script>
