
import { Vue, Options } from "vue-class-component";
import Schedule from "../service/Schedule";
import Toaster from "../helpers/Toaster";
import { camelCase } from "lodash";
import { WeekList } from "../pages/schedule/ISchedule";

@Options({
  props: {
    receiptDetail: Object,
  },
  watch: {
    receiptDetail(obj) {
      this.optionsDialog = obj.optionsDialog;
    },
  },
  emits: ["emitScheduleEvent"],
})
export default class ScheduleOptions extends Vue {
  private toast;
  private schTxn;
  private optionsDialog = false;
  private dateList: WeekList [] = [];
  private searchDate = '';

  private fromCopy = {
    weekDates: '',
    weekDate: '',
    weekNo: 0
  };

  private confirmDelete = '';

  private deleteCopy = {
    weekDates: '',
    weekDate: '',
    weekNo: 0
  };

  private currentWeek = 0;

  private toCopy = {
    weekDates: '',
    weekDate: '',
    weekNo: 0
  };

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.schTxn = new Schedule();
    this.toast = new Toaster();
  }

  mounted()
  {
    this.fetchWeekList();
  }

  fetchWeekList()
  {
    this.schTxn.getWeekList().then(data => {
        const l = this.camelizeKeys(data);
        this.dateList = l.weekList;
        this.currentWeek = l.currentWeek;
    });
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  closeDialog() {
    this.$emit("emitScheduleEvent",'');
    this.optionsDialog = false;
  }

  copySchedule()
  {
    this.schTxn.copySchedule(this.toCopy.weekDate,this.fromCopy.weekDate).then(res => {
        this.$emit("emitScheduleEvent",'');
        this.optionsDialog = false;
        this.toast.handleResponse(res);
    });
  }

  deleteSchedule()
  {
    this.schTxn.deleteSchedule(this.deleteCopy.weekDate).then(res => {
        this.$emit("emitScheduleEvent",'');
        this.optionsDialog = false;
        this.toast.handleResponse(res);
    });
  }

  searchSchedule()
  {
    this.$emit("emitScheduleEvent",this.searchDate);
    this.optionsDialog = false;
  }
}
