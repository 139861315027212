
import { Options, Vue } from "vue-class-component";
import Branch from "../service/Branch";
import Toaster from "../helpers/Toaster";

@Options({
  props: {
    pinDialogStatus: Boolean,
    storeCode: Number,
  },
  watch: {
    pinDialogStatus(val) {
      this.dialogStatus = val;
    },
    storeCode(val) {
      this.clearItems();
      this.storePinSettings.storeID = val;
      this.storePinSettings.storeIDView = val;
    },
  },
  emits: ["changeDialogStatus"],
})
export default class StorePin extends Vue {
  // private store = useStore();
  private dialogStatus = false;
  private storeID = 0;
  private showDialog;
  private toast;
  private branches;
  private submittedShowPin = false;
  private submitted = false;
  private storePinSettings = {
    storeID: 0,
    storeIDView: 0,
    oldPin: "",
    newPin: "",
    confirmPin: "",
    storeManagerPin: "",
    yourPassword: "",
  };

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.branches = new Branch();
    this.toast = new Toaster();
  }

  //USED TO CLEAR THE FIELDS
  clearItems() {
    this.storePinSettings = {
      storeID: 0,
      storeIDView: 0,
      oldPin: "",
      newPin: "",
      confirmPin: "",
      storeManagerPin: "",
      yourPassword: "",
    };
  }

  //SHOW STORE PIN
  changeStorePin(e) {
    e.preventDefault();

    this.submitted = true;

    if (this.storePinSettings.newPin == this.storePinSettings.confirmPin) {
      this.branches.changeStorePin(this.storePinSettings).then((res) => {
        //SHOW NOTIFICATION
        //CLOSE DIALOG
        this.toast.handleResponse(res);
      });
    } else {
      this.toast.showError("Invalid new pin & confirm pin are not matching");
    }
  }

  //SHOW STORE PIN
  showStorePin() {
    this.submittedShowPin = true;
    this.branches.showStorePin(this.storePinSettings).then((res) => {
      if (res != "") {
        this.storePinSettings.yourPassword = res;
      }
    });
  }

  closeDialogBox() {
    this.clearItems();
    this.$emit("changeDialogStatus");
  }
}
