<template>
  <div class="row bb-set-height">
    <div class="col-md-12 p-p-0">
      <h5 class="mt-0 mb-0 checkout-heading pl-2">
        <i class="pi pi-file-o"></i> Order ID : {{ receiptID }}
        <span class="pull-right">
          Due : {{ formatDate(orderDetail.dueDate) }} | Time :
          {{ formatTime(orderDetail.dueTime) }}
        </span>
      </h5>
      <h5 class="text-center mt-3 mb-3">Refund Option</h5>
      <ul class="visitor list-unstyled">
        <li
          class="media pt-3 pb-3 m-0 text-center"
          style="background-color: #f7f7f7"
        >
          <div
            v-if="orderDetail.description != ''"
            class="media-body order-top-header"
          >
            <h6>Memo : {{ orderDetail.description }}</h6>
          </div>
        </li>
        <template v-for="order in orderItems" :key="order">
          <li
            style="border-bottom: 1px dotted #ccc"
            :class="{ 'express-attribute': order.express == 'express' }"
            class="media p-2 mb-0"
          >
            <img
              v-if="order.image"
              class="mr-2 mt-2 rounded-circle"
              width="50"
              :src="imagePath + order.image"
              alt="avatar"
            />
            <div
              :class="{ 'express-attribute': order.express == 'express' }"
              class="media-body"
            >
              <div class="pt-2">
                <label class="mb-0 service-name">
                  <input
                    style="width: 20px; height: 20px"
                    type="checkbox"
                    :disabled="order.checkStatus == 'REFUND' || order.checkStatus == 'BB'"
                    class="radio-style"
                    v-model="checkedItems"
                    :value="order.saleItemId"
                  />
                  {{ order.serviceName }} + Tax
                  <b> (${{ fixLength(order.totalBill) }})</b>
                </label>
                <label class="pull-right">
                  <span v-if="order.checkStatus != ''" class="status-bb mr-4">
                    {{ order.checkStatus }}
                  </span>
                </label>
              </div>
              <div>
                <span style="font-size: 18px">
                  Total Bill + Tax ${{ fixLength(order.totalBill) }}
                </span>
              </div>
              <div v-if="order.description != ''" class="order-top-header">
                Memo: {{ order.description }}
              </div>
              <label v-if="order.express == 'express'">
                <i>
                  Due Date {{ formatDate(order.dueDate) }} | Due Time
                  {{ formatTime(order.dueTime) }}
                </i></label
              >
            </div>
          </li>
        </template>
      </ul>

      <h5 class="mt-0 mb-0 checkout-heading pl-2" id="example-Modal3">
        <i class="pi pi-id-card" aria-hidden="true"></i>
        Refund Details
      </h5>
      <div class="col-md-12" id="buyback_content_area">
        <fieldset>
          <div class="form-group">
            <h5 class="buyback_total_headings">
              TOTAL $ TO BE PAID TO CUSTOMER:
            </h5>
            <input
              type="number"
              class="buyback-text-field"
              readonly
              :value="calculateRefund"
            />
            <small
              class="p-invalid"
              v-if="submitted && !refundItems.totalToPaid"
              >Total Paid is required.</small
            >
          </div>
        </fieldset>
        <fieldset>
          <legend><i class="pi pi-user"></i> Manager & Associate Info</legend>
          <div class="form-group">
            <label> Reason: </label>
            <select
              name="reason"
              v-model.trim="refundItems.reason"
              class="form-control"
            >
              <option value="Quality">Quality</option>
              <option value="Wrong">Wrong</option>
              <option value="Customer Service">Customer Service</option>
            </select>
            <small class="p-invalid" v-if="submitted && !refundItems.reason"
              >Reason is required.</small
            >
          </div>
          <div class="form-group">
            <label class="bb-color-red"> Associate PIN</label>
            <input
              type="password"
              class="form-control"
              required="true"
              v-model.trim="refundItems.employeeId"
              :class="{ 'p-invalid': submitted && !refundItems.employeeId }"
            />
            <small class="p-invalid" v-if="submitted && !refundItems.employeeId"
              >PIN is required.</small
            >
          </div>
          <div class="form-group">
            <label class="bb-color-red">
              Manager who approved buyback PIN?</label
            >
            <input
              type="password"
              class="form-control"
              required="true"
              v-model.trim="refundItems.managerWhoApproved"
              :class="{
                'p-invalid': submitted && !refundItems.managerWhoApproved,
              }"
            />
            <small
              class="p-invalid"
              v-if="submitted && !refundItems.managerWhoApproved"
              >PIN is required.</small
            >
          </div>
        </fieldset>
        <Button
          v-if="refundItems.id == ''"
          label="Proceed to Next"
          @click="proceedNext()"
          icon="pi pi-arrow-right"
          class="p-button-lg p-button-primary pull-right"
        />
      </div>
    </div>
  </div>
  <PaymentScreen
    :receiptDetail="{
      dialogStatus: paymentDialog,
      totalBill: refundItems.totalToPaid,
      itemSource: 'Refund',
      restriction: 'Yes',
      dialogTilte: dialogTilte,
      customerID: customerID,
      customerName: customerName,
      needlePoints: CustomerNeedles,
      closeConfirmation: false,
    }"
    v-on:closePaymentScreenEvent="closePaymentScreen"
    v-on:getProceededPaymentsEvent="getProceededPayments"
  />
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";
import moment from "moment";
import { useStore } from "../store";
import BuyBackService from "../service/BuyBackService";
import Toaster from "../helpers/Toaster";
import { camelCase } from "lodash";
import PaymentScreen from "../components/PaymentScreen.vue";

@Options({
  inheritAttrs: false,
  components: {
    PaymentScreen,
  },
  emits: ["searchReceiptEvent", "updateRedoReceipt", "clearCheckOutEvent"],
})
export default class RefundReceipt extends Vue {
  private dialogTilte = " Refund Payments";
  private txnService;
  private customerID = "";
  private customerName = "";
  private CustomerNeedles = 0;
  private paymentDialog = false;
  private submitted = false;
  private imagePath = "";
  private toast;
  private vuexStore = useStore();
  private orderDetail = {
    dueDate: "",
    dueTime: "",
    description: "",
    cusId: "",
  };

  private checkedItems: string[] = [];

  private orderItems = [
    {
      express: "",
      image: "",
      serviceName: "",
      totalBill: 0,
      saleItemId: "",
      checkStatus: "",
      description: "",
      dueDate: "",
      dueTime: "",
    },
  ];

  private refundItems = {
    id: "",
    totalToPaid: 0,
    reason: "Quality",
    employeeId: "",
    managerWhoApproved: "",
  };

  get receiptID() {
    return this.vuexStore.getters.getReceiptID;
  }

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.txnService = new BuyBackService();
    this.toast = new Toaster();
    this.imagePath = this.txnService.getBaseURL() + "uploads/services/";
  }

  mounted() {
    this.getReceiptBB();
  }

  getReceiptBB() {
    this.txnService.getBBDetails(this.receiptID).then((data) => {
      const itemsDetail = this.camelizeKeys(data);
      this.orderDetail = itemsDetail.orderDetails;
      this.orderItems = itemsDetail.orderItems;
      this.customerID = itemsDetail.orderDetails.cusId;
      this.customerName = itemsDetail.orderDetails.customer;
      this.CustomerNeedles = itemsDetail.orderDetails.customerNeedles;
    });
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  formatDate(value) {
    if (value) {
      return moment(String(value)).format("DD-MM-YYYY");
    }
  }

  formatTime(value) {
    if (value) {
      const time = moment.duration(value);
      return moment(String(time), "HH:mm").format("hh:mm A");
    }
  }

  fixLength(amount) {
    amount = Number(amount);

    if (amount != "") {
      amount = amount.toFixed(2);
    }

    return amount;
  }

  proceedNext() {

    this.submitted = true;

    if(this.refundItems.totalToPaid <= 0)
    {
      this.toast.showError('Invalid refund amount. Amount must be greater then 0');
    }
    else if(this.refundItems.employeeId == '' || this.refundItems.managerWhoApproved == '')
    {
      this.toast.showError('Please enter pin of associate and manager of store');
    }
    else
    {
      this.verfiyManagerAndAssociatePin()
    }
  }

  verfiyManagerAndAssociatePin() {
    this.txnService
      .verifyPINS(
        this.refundItems.managerWhoApproved,
        this.refundItems.employeeId
      )
      .then((res) => {
        this.toast.handleResponse(res);

        if (res.alert == "info") {
          //OPEN PAYMENT SCREEN
          this.paymentDialog = true;
        }
      });
  }

  closePaymentScreen() {
    this.paymentDialog = false;
  }

  getProceededPayments(paymentList) {
    this.txnService
      .sendRefundReq(this.refundItems, paymentList, this.checkedItems, this.receiptID, this.refundItems.totalToPaid)
      .then((res) => {
        if (res.alert == "info") {
          this.$emit("clearCheckOutEvent");
        }

        this.toast.handleResponse(res);
      });
  }

  get calculateRefund() {

    let totalBill = 0;

    this.orderItems.forEach((e) => {
      this.checkedItems.forEach((f) => {
        if (e.saleItemId == f) {
          totalBill = totalBill + Number(e.totalBill);
        }
      });
    });

    this.refundItems.totalToPaid = Number(totalBill);

    return this.fixLength(totalBill);
  }
}
</script>

<style scoped>
.status-bb {
  background-color: #03a900;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  text-transform: uppercase;
}
.approve-bb {
  background-color: #00b100;
  color: #fff;
  padding: 5px;
  margin-right: 5px;
  border-radius: 3px;
  text-transform: uppercase;
}

fieldset {
  border: 1px solid #ddd !important;
  margin: 20px 0px;
  min-width: 0;
  padding: 10px;
  position: relative;
  border-radius: 4px;
  background-color: #f7f7f7;
  padding-left: 10px !important;
}

legend {
  font-size: 14px;
  margin-bottom: 0px;
  width: 50%;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px 5px 5px 10px;
  color: #004c97;
  background-color: #ffffff;
}

.by-opt-radio {
  color: #fff;
  font-size: 18px;
}

.express-attribute {
  background-color: #c00;
  color: #fff !important;
}

.radio-style {
  width: 25px;
  height: 25px;
}

.buyback-text-field {
  height: 60px;
  width: 300px;
  float: right;
  font-size: 60px;
  color: white;
  background-color: #929292;
  border-radius: 5px;
}

.buyback_total_headings {
  color: #c00;
  float: left;
  margin-top: 15px;
  display: block;
  width: 100%;
}

.service-name {
  font-size: 16px;
}

.bb-set-height {
  height: 90.5vh;
  min-height: 90.5vh;
  overflow-y: scroll;
}

.bb-color-red {
  color: #c00;
}
</style>
