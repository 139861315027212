
import { Vue, Options } from "vue-class-component";
import { useStore } from "../store";
import Transaction from "../service/Transaction";
import Toaster from "../helpers/Toaster";

@Options({
  components: {},
  emits: ["updateRedoReceipt"],
})
export default class RedoReceipt extends Vue {
  private store = useStore();
  private txnService;
  private toast;
  private submitted = false;
  private redoList = {
    associateID: "",
    dueDate: "",
    dueTime: "",
    defectReason: "",
  };

  created() {
    this.txnService = new Transaction();
    this.toast = new Toaster();
  }

  get receiptID() {
    return this.store.getters.getReceiptID;
  }

  saveRedo() {
    this.submitted = true;

    if (
      this.redoList.associateID != "" &&
      this.redoList.dueDate != "" &&
      this.redoList.dueTime != "" &&
      this.redoList.defectReason != ""
    ) {
      this.submitted = false;
      this.$emit("updateRedoReceipt", this.redoList);
    } else {
      this.toast.showWarning("Please fill all the given fields");
    }
  }
}
