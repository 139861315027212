
import { Vue, Options } from "vue-class-component";
import CheckinService from "../service/CheckinService";
import { camelCase } from "lodash";
import { useStore } from "../store";
import Toaster from "../helpers/Toaster";
import moment from "moment";
import AutoComplete from "primevue/autocomplete";

import {
  CustomerSearchFields,
  CorporateSearch,
} from "../pages/checkIn/ICheckin";

@Options({
  props: {
    receiptDetail: Object,
  },
  watch: {
    receiptDetail(obj) {
      this.referralorCorpDialog = obj.referralorCorpDialog;
      this.setCustomerHistory(obj.customerHistory);
    },
  },
  components: { AutoComplete },
  emits: ["emitreferralorCorpEvent"],
})
export default class ReferralorCorp extends Vue {
  private toast;
  private submitted = false;
  private referralorCorpDialog = false;
  private itemDetail;
  private checkTxn;
  private customerList: CustomerSearchFields[] = [];
  private corporateList: CorporateSearch[] = [];
  private screenInfo = {
    selectedAction: "",
    referralorCorpID: "",
    referralorCorpName: "",
    accountType: "",
    corporateMeta: [
      {
        fieldName: "",
        fieldValue: "",
      },
    ],
    empNo: "",
    poNo: "",
    requisitionForm: "",
    reqData: "",
    poData: "",
    empData: "",
    priceListId: "",
    Neddle: 0
  };

  private customerHistory = {
    firstName: "",
    lastName: "",
    email: "",
    cell: "",
    phone: "",
    birthday: "",
    address: "",
    custType: "",
    lastVisit: "",
    totalVisits: 0,
    YTDVisit: 0,
    customerSince: "",
    LifeTimeValue: 0,
    FederalRate: "",
    ProRate: "",
    hstExempt: "",
    exempt2: "",
    pstExempt: "",
    exempt1: "",
  };

  private customerServices = [
    {
      name: "",
      totalBill: 0,
    },
  ];

  private timeValue = "";
  private express = false;
  private store = useStore();

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.checkTxn = new CheckinService();
    this.toast = new Toaster();
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  emitreferralDetail() {
    this.submitted = true;

    let checkFields = true;

    if(this.screenInfo.corporateMeta[0].fieldName != '')
    {
      this.screenInfo.corporateMeta.forEach((e) => {
        if (e.fieldValue == "") {
          checkFields = false;
          return false;
        }
      });
    }

    if (
      (checkFields == true &&
        (this.screenInfo.poData != "" || this.screenInfo.poNo == "1") &&
        (this.screenInfo.empData != "" || this.screenInfo.empNo == "1") &&
        (this.screenInfo.reqData != "" || this.screenInfo.requisitionForm == "1")) ||
      this.screenInfo.selectedAction == "" ||
      this.screenInfo.selectedAction == "Referral"
    ) {
      this.$emit("emitreferralorCorpEvent", [this.screenInfo, "next",this.customerHistory]);
      this.submitted = false;
      this.referralorCorpDialog = false;
    } else {
      this.toast.showError("Please fill out all missing required fields");
    }
  }

  get progressBar() {
    return this.store.getters.getProgressBar;
  }

  emitCancelreferral() {
    this.clearScreenInfo();
    this.$emit("emitreferralorCorpEvent", [this.screenInfo, "closeDialog",this.customerHistory]);
    this.referralorCorpDialog = false;
  }

  clearScreenInfo() {
    this.screenInfo = {
      selectedAction: "",
      referralorCorpID: "",
      referralorCorpName: "",
      accountType: "",
      corporateMeta: [
        {
          fieldName: "",
          fieldValue: "",
        },
      ],
      empNo: "",
      poNo: "",
      requisitionForm: "",
      reqData: "",
      poData: "",
      empData: "",
      priceListId: "",
      Neddle: 0
    };
  }

  formatAmount(value) {
    value = Number(value);

    let balance = 0;

    if (value != "") {
      balance = value.toFixed(2);
    }
    return balance;
  }

  toggleCustomerType(type) {
    this.clearScreenInfo();
    this.screenInfo.selectedAction = type;
  }

  setCustomerHistory(history) {
    if (history != null) {
      this.customerServices = history.customerServices;
      this.customerHistory.firstName = history.customerProfile[0].firstName;
      this.customerHistory.lastName = history.customerProfile[0].lastName;
      this.customerHistory.email = history.customerProfile[0].email;
      this.customerHistory.cell = history.customerProfile[0].contact;
      this.customerHistory.phone = history.customerProfile[0].phone;
      this.customerHistory.birthday = this.birthdayDays(
        history.customerProfile[0].birthday
      );
      this.customerHistory.address =
        history.customerProfile[0].address +
        ", " +
        history.customerProfile[0].complex +
        ", " +
        history.customerProfile[0].city;
      this.customerHistory.custType = history.customerProfile[0].customerType;
      this.customerHistory.lastVisit = history.lastVisit;
      this.customerHistory.totalVisits = history.totalVisits;
      this.customerHistory.YTDVisit = history.ytdVisits;
      this.customerHistory.customerSince = history.customerSince;
      this.customerHistory.LifeTimeValue = history.totalService;
      this.customerHistory.hstExempt = history.customerProfile[0].hstExempt;
      this.customerHistory.exempt2 = history.customerProfile[0].exempt2;
      this.customerHistory.pstExempt = history.customerProfile[0].pstExempt;
      this.customerHistory.exempt1 = history.customerProfile[0].exempt1;
      this.screenInfo.Neddle = Number(history.customerProfile[0].custStoreCredit);
    }
  }

  birthdayDays(birthday) {
    let days = "";

    if (birthday != "") {
      const a = moment([
        0,
        moment(birthday).format("MM"),
        moment(birthday).format("DD"),
      ]);

      const b = moment([
        0,
        moment(moment.now()).format("MM"),
        moment(moment.now()).format("DD"),
      ]);

      const d = a.diff(b, "days");

      if (d == 0) {
        days = "Today [" + moment(birthday).format("DD, MMM") + "]";
      } else if (d == 1) {
        days = d + " day [" + moment(birthday).format("DD, MMM") + "]";
      } else {
        days = d + " days [" + moment(birthday).format("DD, MMM") + "]";
      }
    }

    return days;
  }

  searchReferral(event) {
    this.checkTxn.searchCustomer(event.query.trim()).then((data) => {
      const d = this.camelizeKeys(data);
      const searchedResult: CustomerSearchFields[] = [];
      this.customerList = [];

      d.forEach((e) => {
        let mood = "gray";

        const dMood = e.fields.customerReviewsCustomerMood;

        if (Array.isArray(dMood) && dMood) {
          mood = dMood.reverse()[0];
        } else {
          if (dMood == "green") {
            mood = "green";
          } else if (dMood == "red") {
            mood = "red";
          } else {
            mood = "gray";
          }
        }

        const cus: CustomerSearchFields = {
          contact: e.fields.contact,
          firstName: e.fields.firstName,
          lastName: e.fields.lastName,
          email: e.fields.email,
          customerMood: mood,
          customerId: e.fields.customerId,
        };

        searchedResult.push(cus);
      });

      this.customerList = searchedResult;
    });
  }

  searchCorporate(event) {
    this.checkTxn.searchCorporate(event.query.trim()).then((data) => {
      const d = this.camelizeKeys(data);
      this.corporateList = d;
    });
  }

  saveReferral(event) {
    const custInfo = event.value;
    this.screenInfo.referralorCorpName =
      custInfo.firstName + " " + custInfo.lastName;
    this.screenInfo.referralorCorpID = custInfo.customerId;
  }

  saveCorporate(event) {
    const custInfo = event.value;
    this.screenInfo.referralorCorpName =
      custInfo.corporateName + " | " + custInfo.title;
    this.screenInfo.referralorCorpID = custInfo.corporateMainId;
    this.screenInfo.accountType = custInfo.accountType;

    if (this.screenInfo.accountType == "Charge") {
      this.toast.showInfo("Account is charge");
    }

    this.checkTxn.corporateConditions(custInfo.corporateMainId).then((data) => {
      const d = this.camelizeKeys(data);

      if (d.corporateMeta.length > 0) {
        this.screenInfo.corporateMeta = [];

        d.corporateMeta.forEach((e) => {
          const meta = {
            fieldName: e.fieldName,
            fieldValue: "",
          };
          this.screenInfo.corporateMeta.push(meta);
        });
      }

      this.screenInfo.empNo = d.empNo;
      this.screenInfo.poNo = d.poNo;
      this.screenInfo.requisitionForm = d.requisitionForm;
      this.screenInfo.priceListId = d.priceListId;
    });
  }
}
