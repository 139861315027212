<template>
  <Dialog
    v-model:visible="referralorCorpDialog"
    :style="{ width: '100vw', height: '100vh' }"
    :maximizable="false"
    :closable="false"
    position="top"
    :modal="true"
    class="p-fluid"
  >
    <div class="p-d-flex p-jc-between">
      <div class="p-mr-2">
        <h5><i class="pi pi-align-center"></i> Referral OR Corporate ?</h5>
      </div>
      <div class="">
        <span class="p-buttonset">
          <Button
            class="p-button-success btn-lg"
            icon="pi pi-check-circle"
            label="Next"
            @click="emitreferralDetail"
          />
          <Button
            class="p-button-danger btn-lg"
            icon="pi pi-times"
            label="Cancel"
            @click="emitCancelreferral()"
          />
        </span>
      </div>
    </div>
    <div style="height: 0.2em; background-color: #eee" class="p-p-0 p-mt-3">
      <ProgressBar
        v-if="progressBar"
        mode="indeterminate"
        style="height: 0.2em"
      />
    </div>
    <div class="row" style="background-color: #f7f7f7">
      <div class="col-md-4 p-mt-2 border-left">
        <h5>
          <i class="pi pi-replay"></i> Customer History <br />
          <small>Customer history of visiting store</small>
        </h5>
        <table class="table table-bordered table-striped referral-table">
          <tr>
            <td><b>Name</b></td>
            <td>
              <h5>
                {{ customerHistory.firstName }} {{ customerHistory.lastName }}
              </h5>
            </td>
          </tr>
          <tr>
            <td><b>Email</b></td>
            <td>
              <h5>{{ customerHistory.email }}</h5>
            </td>
          </tr>
          <tr>
            <td><b>Cell</b></td>
            <td>
              <h5>{{ customerHistory.cell }}</h5>
            </td>
          </tr>
          <tr>
            <td><b>Phone</b></td>
            <td>
              <h5>{{ customerHistory.phone }}</h5>
            </td>
          </tr>
          <tr>
            <td><b>Address</b></td>
            <td>{{ customerHistory.address }}</td>
          </tr>
          <tr>
            <td><b>Cust Type</b></td>
            <td>
              <h5>{{ customerHistory.custType }}</h5>
            </td>
          </tr>
          <tr>
            <td><b>Last Visits</b></td>
            <td>
              <h5>{{ customerHistory.lastVisit }}</h5>
            </td>
          </tr>
          <tr>
            <td><b>Total Visits</b></td>
            <td>
              <h5>#{{ customerHistory.totalVisits }}</h5>
            </td>
          </tr>
          <tr>
            <td><b>YTD Visits</b></td>
            <td>
              <h5>#{{ customerHistory.YTDVisit }}</h5>
            </td>
          </tr>
          <tr>
            <td><b>Cust Since</b></td>
            <td>
              <h5>{{ customerHistory.customerSince }}</h5>
            </td>
          </tr>
          <tr>
            <td><b>Birthday</b></td>
            <td>
              <h5>{{ customerHistory.birthday }}</h5>
            </td>
          </tr>
          <tr>
            <td><b>Life Time Value</b></td>
            <td>
              <h5>${{ formatAmount(customerHistory.LifeTimeValue) }}</h5>
            </td>
          </tr>
          <tr>
            <td><b>Federal Rate </b></td>
            <td style="background-color: #c00; color: #fff">
              <h5 v-if="customerHistory.hstExempt == 'no'">Not Exempt</h5>
              <h5 v-if="customerHistory.hstExempt != 'no'">
                Exempt( {{ customerHistory.exempt2 }})
              </h5>
            </td>
          </tr>
          <tr>
            <td><b>Prov/State Rate </b></td>
            <td style="background-color: #c00; color: #fff">
              <h5 v-if="customerHistory.pstExempt == 'no'">Not Exempt</h5>
              <h5 v-if="customerHistory.pstExempt != 'no'">
                Exempt( {{ customerHistory.exempt1 }})
              </h5>
            </td>
          </tr>
        </table>
        <h5>
          <i class="pi pi-user"></i>
          {{ customerHistory.firstName }} {{ customerHistory.lastName }}
          Top 3 Services
        </h5>
        <table class="table table-bordered table-striped p-mt-3">
          <tr>
            <td class="p-1"><h5>Services</h5></td>
            <td class="p-1"><h5>Total</h5></td>
          </tr>
          <tr v-for="items in customerServices" :key="items">
            <td class="p-1">{{ items.name }}</td>
            <td class="p-1">
              <b>${{ formatAmount(items.totalBill) }}</b>
            </td>
          </tr>
        </table>
      </div>
      <div class="col-md-4 p-mt-2 border-left">
        <h5>
          Is It Referral ? <br />
          <small> Click Yes if it is a referral </small>
        </h5>
        <p
          v-if="
            screenInfo.selectedAction == 'Referral' &&
            screenInfo.referralorCorpID != ''
          "
        >
          <b>
            Referral :
            <span class="pull-right">
              {{ screenInfo.referralorCorpName }}
            </span>
          </b>
        </p>
        <hr />
        <Button
          class="p-button-primary btn-lg"
          icon="pi pi-check-circle"
          label="Yes"
          @click="toggleCustomerType('Referral')"
        />
        <div
          class="p-fluid p-mt-2"
          v-if="screenInfo.selectedAction == 'Referral'"
        >
          <label>Referral Customer</label>
          <AutoComplete
            autoFocus
            :delay="1000"
            :minLength="3"
            @item-select="saveReferral($event)"
            scrollHeight="400"
            v-model="screenInfo.referralorCorpName"
            :suggestions="customerList"
            placeholder="Search Customer"
            @complete="searchReferral($event)"
            :dropdown="false"
          >
            <template #item="slotProps">
              <div>
                Cus Name :
                <b class="pull-right">
                  {{ slotProps.item.firstName.toUpperCase() }}
                  {{ slotProps.item.lastName.toUpperCase() }}
                </b>
              </div>
              <div>
                Email :
                <span class="pull-right"> {{ slotProps.item.email }} </span>
              </div>
              <div>
                Contact :
                <span class="pull-right"> {{ slotProps.item.contact }} </span>
              </div>
              <div
                :class="{
                  moodRed: slotProps.item.customerMood == 'red',
                  moodGreen: slotProps.item.customerMood == 'green',
                  moodGray: slotProps.item.customerMood == 'gray',
                }"
                class="p-p-1"
              ></div>
            </template>
          </AutoComplete>
        </div>
      </div>
      <div class="col-md-4 p-mt-2 border-left">
        <h5>
          OR Corporate ? <br />
          <small>Click Yes if it is a Corporate</small>
        </h5>
        <p
          class="p-mb-5"
          v-if="
            screenInfo.selectedAction == 'corporate' &&
            screenInfo.referralorCorpID != ''
          "
        >
          <b>
            Corporate :
            <span class="pull-right">
              {{ screenInfo.referralorCorpName }}
            </span>
          </b>
        </p>
        <hr />
        <Button
          class="p-button-primary btn-lg"
          icon="pi pi-check-circle"
          label="Yes"
          @click="toggleCustomerType('corporate')"
        />
        <div
          class="p-fluid p-mt-2"
          v-if="screenInfo.selectedAction == 'corporate'"
        >
          <label>Corporate</label>
          <AutoComplete
            autoFocus
            :delay="1000"
            :minLength="3"
            @item-select="saveCorporate($event)"
            scrollHeight="400"
            v-model="screenInfo.referralorCorpName"
            :suggestions="corporateList"
            placeholder="Search Corporate"
            @complete="searchCorporate($event)"
            :dropdown="false"
          >
            <template #item="slotProps">
              <div>
                Corporate ID :
                <b class="pull-right">
                  {{ slotProps.item.corporateMainId }}
                </b>
              </div>
              <div>
                Corporate Head :
                <span class="pull-right">
                  {{ slotProps.item.corporateName }}
                </span>
              </div>
              <div>
                Corporate Title :
                <span class="pull-right"> {{ slotProps.item.title }} </span>
              </div>
              <div>
                Account Type :
                <span style="color: #dc3545" class="pull-right">
                  {{ slotProps.item.accountType }}
                </span>
              </div>
              <div>
                Address :
                <span class="pull-right"> {{ slotProps.item.address }} </span>
              </div>
              <div>
                Email :
                <span class="pull-right"> {{ slotProps.item.contact }} </span>
              </div>
              <div class="border-bottom">
                Contact :
                <span class="pull-right"> {{ slotProps.item.contact }} </span>
              </div>
            </template>
          </AutoComplete>
          <h5
            class="checkout-heading p-mt-4"
            v-if="
              screenInfo.empNo == '0' ||
              screenInfo.poNo == '0' ||
              screenInfo.requisitionForm == '0'
            "
          >
            <i class="pi pi-info-circle"></i>
            Corporate Required Details (All Required)
          </h5>
          <div class="p-field p-mt-3" v-if="screenInfo.empNo == '0'">
            <label>Employee No</label>
            <InputText
              v-model.trim="screenInfo.empData"
              required="true"
              :class="{ 'p-invalid': submitted && !screenInfo.empData }"
            />
            <small class="p-invalid" v-if="submitted && !screenInfo.empData"
              >Employee No is required.</small
            >
          </div>
          <div class="p-field" v-if="screenInfo.poNo == '0'">
            <label>PO No</label>
            <InputText
              v-model.trim="screenInfo.poData"
              required="true"
              :class="{ 'p-invalid': submitted && !screenInfo.poData }"
            />
            <small class="p-invalid" v-if="submitted && !screenInfo.poData"
              >PO is required.</small
            >
          </div>
          <div class="p-field" v-if="screenInfo.requisitionForm == '0'">
            <label>Req Form</label>
            <InputText
              v-model.trim="screenInfo.reqData"
              required="true"
              :class="{ 'p-invalid': submitted && !screenInfo.reqData }"
            />
            <small class="p-invalid" v-if="submitted && !screenInfo.reqData"
              >Requisition Form is required.</small
            >
          </div>
          <h5
            class="checkout-heading p-mt-4 p-mb-4"
            v-if="screenInfo.corporateMeta[0].fieldName != ''"
          >
            <i class="pi pi-info-circle"></i>
            Additional Info (All Required)
          </h5>
          <template v-if="screenInfo.corporateMeta[0].fieldName != ''">
            <div
              class="p-field"
              v-for="addInfo in screenInfo.corporateMeta"
              :key="addInfo"
            >
              <label>{{ addInfo.fieldName }}</label>
              <InputText
                v-model.trim="addInfo.fieldValue"
                required="true"
                :class="{ 'p-invalid': submitted && !addInfo.fieldValue }"
              />
              <small class="p-invalid" v-if="submitted && !addInfo.fieldValue"
                >{{ addInfo.fieldName }} is required.</small
              >
            </div>
          </template>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";
import CheckinService from "../service/CheckinService";
import { camelCase } from "lodash";
import { useStore } from "../store";
import Toaster from "../helpers/Toaster";
import moment from "moment";
import AutoComplete from "primevue/autocomplete";

import {
  CustomerSearchFields,
  CorporateSearch,
} from "../pages/checkIn/ICheckin";

@Options({
  props: {
    receiptDetail: Object,
  },
  watch: {
    receiptDetail(obj) {
      this.referralorCorpDialog = obj.referralorCorpDialog;
      this.setCustomerHistory(obj.customerHistory);
    },
  },
  components: { AutoComplete },
  emits: ["emitreferralorCorpEvent"],
})
export default class ReferralorCorp extends Vue {
  private toast;
  private submitted = false;
  private referralorCorpDialog = false;
  private itemDetail;
  private checkTxn;
  private customerList: CustomerSearchFields[] = [];
  private corporateList: CorporateSearch[] = [];
  private screenInfo = {
    selectedAction: "",
    referralorCorpID: "",
    referralorCorpName: "",
    accountType: "",
    corporateMeta: [
      {
        fieldName: "",
        fieldValue: "",
      },
    ],
    empNo: "",
    poNo: "",
    requisitionForm: "",
    reqData: "",
    poData: "",
    empData: "",
    priceListId: "",
    Neddle: 0
  };

  private customerHistory = {
    firstName: "",
    lastName: "",
    email: "",
    cell: "",
    phone: "",
    birthday: "",
    address: "",
    custType: "",
    lastVisit: "",
    totalVisits: 0,
    YTDVisit: 0,
    customerSince: "",
    LifeTimeValue: 0,
    FederalRate: "",
    ProRate: "",
    hstExempt: "",
    exempt2: "",
    pstExempt: "",
    exempt1: "",
  };

  private customerServices = [
    {
      name: "",
      totalBill: 0,
    },
  ];

  private timeValue = "";
  private express = false;
  private store = useStore();

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.checkTxn = new CheckinService();
    this.toast = new Toaster();
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  emitreferralDetail() {
    this.submitted = true;

    let checkFields = true;

    if(this.screenInfo.corporateMeta[0].fieldName != '')
    {
      this.screenInfo.corporateMeta.forEach((e) => {
        if (e.fieldValue == "") {
          checkFields = false;
          return false;
        }
      });
    }

    if (
      (checkFields == true &&
        (this.screenInfo.poData != "" || this.screenInfo.poNo == "1") &&
        (this.screenInfo.empData != "" || this.screenInfo.empNo == "1") &&
        (this.screenInfo.reqData != "" || this.screenInfo.requisitionForm == "1")) ||
      this.screenInfo.selectedAction == "" ||
      this.screenInfo.selectedAction == "Referral"
    ) {
      this.$emit("emitreferralorCorpEvent", [this.screenInfo, "next",this.customerHistory]);
      this.submitted = false;
      this.referralorCorpDialog = false;
    } else {
      this.toast.showError("Please fill out all missing required fields");
    }
  }

  get progressBar() {
    return this.store.getters.getProgressBar;
  }

  emitCancelreferral() {
    this.clearScreenInfo();
    this.$emit("emitreferralorCorpEvent", [this.screenInfo, "closeDialog",this.customerHistory]);
    this.referralorCorpDialog = false;
  }

  clearScreenInfo() {
    this.screenInfo = {
      selectedAction: "",
      referralorCorpID: "",
      referralorCorpName: "",
      accountType: "",
      corporateMeta: [
        {
          fieldName: "",
          fieldValue: "",
        },
      ],
      empNo: "",
      poNo: "",
      requisitionForm: "",
      reqData: "",
      poData: "",
      empData: "",
      priceListId: "",
      Neddle: 0
    };
  }

  formatAmount(value) {
    value = Number(value);

    let balance = 0;

    if (value != "") {
      balance = value.toFixed(2);
    }
    return balance;
  }

  toggleCustomerType(type) {
    this.clearScreenInfo();
    this.screenInfo.selectedAction = type;
  }

  setCustomerHistory(history) {
    if (history != null) {
      this.customerServices = history.customerServices;
      this.customerHistory.firstName = history.customerProfile[0].firstName;
      this.customerHistory.lastName = history.customerProfile[0].lastName;
      this.customerHistory.email = history.customerProfile[0].email;
      this.customerHistory.cell = history.customerProfile[0].contact;
      this.customerHistory.phone = history.customerProfile[0].phone;
      this.customerHistory.birthday = this.birthdayDays(
        history.customerProfile[0].birthday
      );
      this.customerHistory.address =
        history.customerProfile[0].address +
        ", " +
        history.customerProfile[0].complex +
        ", " +
        history.customerProfile[0].city;
      this.customerHistory.custType = history.customerProfile[0].customerType;
      this.customerHistory.lastVisit = history.lastVisit;
      this.customerHistory.totalVisits = history.totalVisits;
      this.customerHistory.YTDVisit = history.ytdVisits;
      this.customerHistory.customerSince = history.customerSince;
      this.customerHistory.LifeTimeValue = history.totalService;
      this.customerHistory.hstExempt = history.customerProfile[0].hstExempt;
      this.customerHistory.exempt2 = history.customerProfile[0].exempt2;
      this.customerHistory.pstExempt = history.customerProfile[0].pstExempt;
      this.customerHistory.exempt1 = history.customerProfile[0].exempt1;
      this.screenInfo.Neddle = Number(history.customerProfile[0].custStoreCredit);
    }
  }

  birthdayDays(birthday) {
    let days = "";

    if (birthday != "") {
      const a = moment([
        0,
        moment(birthday).format("MM"),
        moment(birthday).format("DD"),
      ]);

      const b = moment([
        0,
        moment(moment.now()).format("MM"),
        moment(moment.now()).format("DD"),
      ]);

      const d = a.diff(b, "days");

      if (d == 0) {
        days = "Today [" + moment(birthday).format("DD, MMM") + "]";
      } else if (d == 1) {
        days = d + " day [" + moment(birthday).format("DD, MMM") + "]";
      } else {
        days = d + " days [" + moment(birthday).format("DD, MMM") + "]";
      }
    }

    return days;
  }

  searchReferral(event) {
    this.checkTxn.searchCustomer(event.query.trim()).then((data) => {
      const d = this.camelizeKeys(data);
      const searchedResult: CustomerSearchFields[] = [];
      this.customerList = [];

      d.forEach((e) => {
        let mood = "gray";

        const dMood = e.fields.customerReviewsCustomerMood;

        if (Array.isArray(dMood) && dMood) {
          mood = dMood.reverse()[0];
        } else {
          if (dMood == "green") {
            mood = "green";
          } else if (dMood == "red") {
            mood = "red";
          } else {
            mood = "gray";
          }
        }

        const cus: CustomerSearchFields = {
          contact: e.fields.contact,
          firstName: e.fields.firstName,
          lastName: e.fields.lastName,
          email: e.fields.email,
          customerMood: mood,
          customerId: e.fields.customerId,
        };

        searchedResult.push(cus);
      });

      this.customerList = searchedResult;
    });
  }

  searchCorporate(event) {
    this.checkTxn.searchCorporate(event.query.trim()).then((data) => {
      const d = this.camelizeKeys(data);
      this.corporateList = d;
    });
  }

  saveReferral(event) {
    const custInfo = event.value;
    this.screenInfo.referralorCorpName =
      custInfo.firstName + " " + custInfo.lastName;
    this.screenInfo.referralorCorpID = custInfo.customerId;
  }

  saveCorporate(event) {
    const custInfo = event.value;
    this.screenInfo.referralorCorpName =
      custInfo.corporateName + " | " + custInfo.title;
    this.screenInfo.referralorCorpID = custInfo.corporateMainId;
    this.screenInfo.accountType = custInfo.accountType;

    if (this.screenInfo.accountType == "Charge") {
      this.toast.showInfo("Account is charge");
    }

    this.checkTxn.corporateConditions(custInfo.corporateMainId).then((data) => {
      const d = this.camelizeKeys(data);

      if (d.corporateMeta.length > 0) {
        this.screenInfo.corporateMeta = [];

        d.corporateMeta.forEach((e) => {
          const meta = {
            fieldName: e.fieldName,
            fieldValue: "",
          };
          this.screenInfo.corporateMeta.push(meta);
        });
      }

      this.screenInfo.empNo = d.empNo;
      this.screenInfo.poNo = d.poNo;
      this.screenInfo.requisitionForm = d.requisitionForm;
      this.screenInfo.priceListId = d.priceListId;
    });
  }
}
</script>

<style scoped>
.referral-table td {
  padding: 5px;
}

.border-left {
  border-left: 1px solid #ccc;
}

.moodRed {
  background-color: #dc3545;
}

.moodGreen {
  background-color: green;
}

.moodGray {
  background-color: gray;
}

.border-bottom {
  border-bottom: 2px solid #ccc;
  padding-bottom: 2px;
}
</style>
