<template>
  <Dialog
    v-model:visible="pauseDialog"
    :style="{ width: '100vw' }"
    :maximizable="false"
    :closable="false"
    position="top"
    :modal="true"
    class="p-fluid"
  >
    <div class="p-d-flex p-jc-between">
      <div class="">
        <h5>
          <i class="pi pi-caret-right"></i>
          Resume / Pause Receipts
        </h5>
      </div>
      <div class="p-mr-2">
        <Button
          class="p-button-danger"
          icon="pi pi-times"
          label="Close"
          @click="emitPauseDetail()"
        />
      </div>
    </div>
    <div class="row p-mt-3">
      <div class="col-md-12">
        <table class="table table-bordered table-striped table-style">
          <thead>
            <tr>
              <td>Date</td>
              <td>Customer Name</td>
              <td>Associate ID</td>
              <td>Total Items</td>
              <td>Total Bill</td>
              <td>Total Paid</td>
              <td>Action</td>
            </tr>
          </thead>
          <tbody>
            <tr v-if="customerReceipt.invoiceServices.length > 0">
              <td>{{ formatDate(customerReceipt.date) }}</td>
              <td>{{ customerReceipt.customer }}</td>
              <td>{{ customerReceipt.employeeId }}</td>
              <td>{{ customerReceipt.invoiceServices.length }}</td>
              <td>{{ formatAmount(customerReceipt.totalBill) }}</td>
              <td>{{ formatAmount(customerReceipt.totalPaid) }}</td>
              <td><Button @click="pauseMyReceipt()" class="p-button-primary p-p-1"  icon="pi pi-pause" label="Pause" /></td>
            </tr>
             <tr v-for="(receipt,index) in savedReceipts" :key="receipt">
              <td>{{ formatDate(receipt.date) }}</td>
              <td>{{ receipt.customer }}</td>
              <td>{{ receipt.employeeId }}</td>
              <td>{{ receipt.invoiceServices.length }}</td>
              <td>{{ formatAmount(receipt.totalBill) }}</td>
              <td>{{ formatAmount(receipt.totalPaid) }}</td>
              <td>
                  <span class="p-buttonset">
                    <Button @click="resumeMyReceipt(receipt,index)" class="p-button-success p-p-1"  icon="pi pi-play"  />
                    <Button @click="openConfirmationDialog(index)" class="p-button-danger p-p-1"  icon="pi pi-times" />
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </Dialog>

  <Dialog
    v-model:visible="statusDialog"
    :style="{ width: '450px' }"
    header="Confirm">
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
      <span>Are you sure to remove this receipt ? </span>
    </div>
    <template #footer>
      <Button
        label="No"
        icon="pi pi-times"
        class="p-button-text"
        @click="statusDialog = false"
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        class="p-button-danger"
        @click="removeMyReceipt()"
      />
    </template>
  </Dialog>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";
import CheckinService from "../service/CheckinService";
import { camelCase } from "lodash";
import { useStore } from "../store";
import Toaster from "../helpers/Toaster";
import moment from "moment";

import {
  ReceiptType
} from "../pages/checkIn/ICheckin";

@Options({
  props: {
    receiptDetail: Object,
  },
  watch: {
    receiptDetail(obj) {
      this.pauseDialog     = obj.pauseReceiptDialog;
      this.customerReceipt = obj.customerReceipt;
    },
  },
  components: {},
  emits: ["emitPauseEvent"],
})
export default class PauseReceipt extends Vue {
  private toast;
  private tempPausedIndex = 0;
  private pauseDialog = false;
  private statusDialog = false;
  private customerReceipt: ReceiptType =  {
    id: "",
    date: '',
    timeEntrance: "",
    dueDate: "",
    dueTime: "",
    canadaExempt: "",
    provinceExempt: "",
    exempt1: "",
    exempt2: "",
    discount: 0,
    discountMethod: "",
    discountName: "",
    discountId: "",
    description: "",
    cusId: "",
    customer: "",
    customerTel: "",
    customerEmail: "",
    taxCode: "",
    employeeName: "",
    branchCode: "",
    telephone: "",
    branchName: "",
    address: "",
    customerNeedles: 0,
    totalBill: 0,
    totalPaid: 0,
    totalExpress: 0,
    totalQty: 0,
    storeId: "",
    employeeId: "1234",
    priceListId: "",
    accountType:"",
    referalOrCorp: "",
    referalOrCorpId: "",
    corpEmp: "",
    corpPo: "",
    corpReq: "",
    corporateMeta: [],
    status: "",
    taxMethod: "",
    taxAmount: 0,
    taxName: "",
    paymentMethod: "",
    totalTendered: 0,
    totalChange: 0,
    invoiceServices: [],
    invoicePayment: [],
  };
  
  private receiptStacked: ReceiptType [] = [];
  private checkTxn;
  private dateValue = "";
  private localStorageKey = "storeReceiptsStitchIt";
  private timeValue = "";
  private express = false;
  private store = useStore();

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.checkTxn = new CheckinService();
    this.toast = new Toaster();
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  emitPauseDetail() {
    this.$emit("emitPauseEvent", "close");
    this.pauseDialog = false;
  }

  formatDate(value) {
    if (value) {
      return moment(String(value)).format("DD-MM-YYYY");
    }
  }

  formatAmount(value) {
    value = Number(value);

    let balance = 0;

    if (value != "") {
      balance = value.toFixed(2);
    }
    return balance;
  }


  get savedReceipts()
  {

    this.receiptStacked = [];

    const oldReceipts = String(localStorage.getItem(this.localStorageKey));

    let parsedReceipt = JSON.parse(oldReceipts);

    if(parsedReceipt == null)
    {
        parsedReceipt = [];
    }
    this.receiptStacked = parsedReceipt;

    return this.receiptStacked;
  }

  pauseMyReceipt()
  {
    this.receiptStacked.push(this.customerReceipt);

    localStorage.setItem(this.localStorageKey,JSON.stringify(this.receiptStacked));

    this.toast.showSuccess('Receipt Paused Successfully');

    this.$emit("emitPauseEvent", ["paused",{}]);

    this.pauseDialog = false;
  }

  resumeMyReceipt(receipt,index)
  {
      if(this.customerReceipt.invoiceServices.length > 0)
      {
        this.toast.showInfo('Please clear the  current details to process futher');

        this.$emit("emitPauseEvent", "close");
        this.pauseDialog = false;
      }
      else
      {
        this.$emit("emitPauseEvent", ["resume",receipt]);
        this.pauseDialog = false;

        this.receiptStacked.splice(index,1);
        localStorage.setItem(this.localStorageKey,JSON.stringify(this.receiptStacked));
        this.toast.showSuccess('Receipt resumed successfully');
      }
  }

  removeMyReceipt()
  {
    this.receiptStacked.splice(this.tempPausedIndex,1);
    this.statusDialog = false;
    localStorage.setItem(this.localStorageKey,JSON.stringify(this.receiptStacked));
    this.toast.showSuccess('Receipt removed successfully');
  }

  openConfirmationDialog(index)
  {
      this.tempPausedIndex = index;
      this.statusDialog = true;
  }
}
</script>

<style scoped>
.table-style td {
  padding: 4px;
}
</style>
