
import { Vue, Options } from "vue-class-component";
import moment from "moment";
import { useStore } from "../store";
import BuyBackService from "../service/BuyBackService";
import Toaster from "../helpers/Toaster";
import { camelCase } from "lodash";
import PaymentScreen from "../components/PaymentScreen.vue";

@Options({
  inheritAttrs: false,
  components: {
    PaymentScreen,
  },
  emits: ["searchReceiptEvent", "updateRedoReceipt", "clearCheckOutEvent"],
})
export default class RefundReceipt extends Vue {
  private dialogTilte = " Refund Payments";
  private txnService;
  private customerID = "";
  private customerName = "";
  private CustomerNeedles = 0;
  private paymentDialog = false;
  private submitted = false;
  private imagePath = "";
  private toast;
  private vuexStore = useStore();
  private orderDetail = {
    dueDate: "",
    dueTime: "",
    description: "",
    cusId: "",
  };

  private checkedItems: string[] = [];

  private orderItems = [
    {
      express: "",
      image: "",
      serviceName: "",
      totalBill: 0,
      saleItemId: "",
      checkStatus: "",
      description: "",
      dueDate: "",
      dueTime: "",
    },
  ];

  private refundItems = {
    id: "",
    totalToPaid: 0,
    reason: "Quality",
    employeeId: "",
    managerWhoApproved: "",
  };

  get receiptID() {
    return this.vuexStore.getters.getReceiptID;
  }

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.txnService = new BuyBackService();
    this.toast = new Toaster();
    this.imagePath = this.txnService.getBaseURL() + "uploads/services/";
  }

  mounted() {
    this.getReceiptBB();
  }

  getReceiptBB() {
    this.txnService.getBBDetails(this.receiptID).then((data) => {
      const itemsDetail = this.camelizeKeys(data);
      this.orderDetail = itemsDetail.orderDetails;
      this.orderItems = itemsDetail.orderItems;
      this.customerID = itemsDetail.orderDetails.cusId;
      this.customerName = itemsDetail.orderDetails.customer;
      this.CustomerNeedles = itemsDetail.orderDetails.customerNeedles;
    });
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  formatDate(value) {
    if (value) {
      return moment(String(value)).format("DD-MM-YYYY");
    }
  }

  formatTime(value) {
    if (value) {
      const time = moment.duration(value);
      return moment(String(time), "HH:mm").format("hh:mm A");
    }
  }

  fixLength(amount) {
    amount = Number(amount);

    if (amount != "") {
      amount = amount.toFixed(2);
    }

    return amount;
  }

  proceedNext() {

    this.submitted = true;

    if(this.refundItems.totalToPaid <= 0)
    {
      this.toast.showError('Invalid refund amount. Amount must be greater then 0');
    }
    else if(this.refundItems.employeeId == '' || this.refundItems.managerWhoApproved == '')
    {
      this.toast.showError('Please enter pin of associate and manager of store');
    }
    else
    {
      this.verfiyManagerAndAssociatePin()
    }
  }

  verfiyManagerAndAssociatePin() {
    this.txnService
      .verifyPINS(
        this.refundItems.managerWhoApproved,
        this.refundItems.employeeId
      )
      .then((res) => {
        this.toast.handleResponse(res);

        if (res.alert == "info") {
          //OPEN PAYMENT SCREEN
          this.paymentDialog = true;
        }
      });
  }

  closePaymentScreen() {
    this.paymentDialog = false;
  }

  getProceededPayments(paymentList) {
    this.txnService
      .sendRefundReq(this.refundItems, paymentList, this.checkedItems, this.receiptID, this.refundItems.totalToPaid)
      .then((res) => {
        if (res.alert == "info") {
          this.$emit("clearCheckOutEvent");
        }

        this.toast.handleResponse(res);
      });
  }

  get calculateRefund() {

    let totalBill = 0;

    this.orderItems.forEach((e) => {
      this.checkedItems.forEach((f) => {
        if (e.saleItemId == f) {
          totalBill = totalBill + Number(e.totalBill);
        }
      });
    });

    this.refundItems.totalToPaid = Number(totalBill);

    return this.fixLength(totalBill);
  }
}
