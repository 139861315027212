<template>
  <Dialog
    v-model:visible="optionsDialog"
    :style="{ width: '80vw' }"
    :maximizable="true"
    :closable="true"
    @hide="closeDialog"
    position="center"
    class="p-fluid"
  >
    <template #header>
      <h6 class="p-dialog-titlebar p-dialog-titlebar-icon">
        Search, Copy OR Delete Week Schedule <br />
        <small>Current Week No: {{currentWeek}}</small>
      </h6>
    </template>
    <div class="row p-mb-5">
      <div class="col-md-4">
        <h5 class="p-mt-3">
          <i class="pi pi-search"></i>
          Search Week Schedule
        </h5>
        <div class="p-field">
            <label>Select any week date : </label>
            <input type="date" v-model="searchDate" class="form-control input-lg" />
        </div>
        <div class="p-field">
          <Button
            icon="pi pi-plus-circle"
            label="Search"
            @click="searchSchedule"
            class="p-button-primary p-button-lg"
          />
        </div>
      </div>
      <div class="col-md-4">
        <h5 class="p-mt-3">
          <i class="pi pi-file"></i>
           Copy Week Schedule
        </h5>
        <div class="p-field">
            <label>Copy Week Schedule From : </label>
           <Dropdown
            v-model="fromCopy"
            :options="dateList"
            optionLabel="weekDates"
            :showClear="true"
            />
        </div>
        <div class="p-field">
            <label>Copy Week Schedule From : </label>
           <Dropdown
            v-model="toCopy"
            :options="dateList"
            optionLabel="weekDates"
            :showClear="true"
            />
        </div>
        <div class="p-field">
          <Button
            icon="pi pi-file"
            @click="copySchedule"
            label="Copy Schedule"
            class="p-button-primary p-button-lg"
          />
        </div>
      </div>
     <div class="col-md-4">
        <h5 class="p-mt-3">
          <i class="pi pi-trash"></i>
            Delete Week Schedule
        </h5>
        <div class="p-field">
            <label>Search Week:  : </label>
           <Dropdown
            v-model="deleteCopy"
            :options="dateList"
            optionLabel="weekDates"
            :showClear="true"
            />
        </div>
        <div class="p-field">
            <label>To Delete Write ( delete ) : </label>
           <InputText
            v-model="confirmDelete"
          />
        </div>
        <div class="p-field">
          <Button
          :disabled="confirmDelete != 'delete'"
            icon="pi pi-trash"
            label="Delete Schedule"
            @click="deleteSchedule"
            class="p-button-danger p-button-lg"
          />
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";
import Schedule from "../service/Schedule";
import Toaster from "../helpers/Toaster";
import { camelCase } from "lodash";
import { WeekList } from "../pages/schedule/ISchedule";

@Options({
  props: {
    receiptDetail: Object,
  },
  watch: {
    receiptDetail(obj) {
      this.optionsDialog = obj.optionsDialog;
    },
  },
  emits: ["emitScheduleEvent"],
})
export default class ScheduleOptions extends Vue {
  private toast;
  private schTxn;
  private optionsDialog = false;
  private dateList: WeekList [] = [];
  private searchDate = '';

  private fromCopy = {
    weekDates: '',
    weekDate: '',
    weekNo: 0
  };

  private confirmDelete = '';

  private deleteCopy = {
    weekDates: '',
    weekDate: '',
    weekNo: 0
  };

  private currentWeek = 0;

  private toCopy = {
    weekDates: '',
    weekDate: '',
    weekNo: 0
  };

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.schTxn = new Schedule();
    this.toast = new Toaster();
  }

  mounted()
  {
    this.fetchWeekList();
  }

  fetchWeekList()
  {
    this.schTxn.getWeekList().then(data => {
        const l = this.camelizeKeys(data);
        this.dateList = l.weekList;
        this.currentWeek = l.currentWeek;
    });
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  closeDialog() {
    this.$emit("emitScheduleEvent",'');
    this.optionsDialog = false;
  }

  copySchedule()
  {
    this.schTxn.copySchedule(this.toCopy.weekDate,this.fromCopy.weekDate).then(res => {
        this.$emit("emitScheduleEvent",'');
        this.optionsDialog = false;
        this.toast.handleResponse(res);
    });
  }

  deleteSchedule()
  {
    this.schTxn.deleteSchedule(this.deleteCopy.weekDate).then(res => {
        this.$emit("emitScheduleEvent",'');
        this.optionsDialog = false;
        this.toast.handleResponse(res);
    });
  }

  searchSchedule()
  {
    this.$emit("emitScheduleEvent",this.searchDate);
    this.optionsDialog = false;
  }
}
</script>

<style scoped>
</style>
