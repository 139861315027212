
import { Vue, Options } from "vue-class-component";
import CheckinService from "../service/CheckinService";
import { camelCase } from "lodash";
import { useStore } from "../store";
import Toaster from "../helpers/Toaster";
import moment from "moment";

import {
  ReceiptType
} from "../pages/checkIn/ICheckin";

@Options({
  props: {
    receiptDetail: Object,
  },
  watch: {
    receiptDetail(obj) {
      this.pauseDialog     = obj.pauseReceiptDialog;
      this.customerReceipt = obj.customerReceipt;
    },
  },
  components: {},
  emits: ["emitPauseEvent"],
})
export default class PauseReceipt extends Vue {
  private toast;
  private tempPausedIndex = 0;
  private pauseDialog = false;
  private statusDialog = false;
  private customerReceipt: ReceiptType =  {
    id: "",
    date: '',
    timeEntrance: "",
    dueDate: "",
    dueTime: "",
    canadaExempt: "",
    provinceExempt: "",
    exempt1: "",
    exempt2: "",
    discount: 0,
    discountMethod: "",
    discountName: "",
    discountId: "",
    description: "",
    cusId: "",
    customer: "",
    customerTel: "",
    customerEmail: "",
    taxCode: "",
    employeeName: "",
    branchCode: "",
    telephone: "",
    branchName: "",
    address: "",
    customerNeedles: 0,
    totalBill: 0,
    totalPaid: 0,
    totalExpress: 0,
    totalQty: 0,
    storeId: "",
    employeeId: "1234",
    priceListId: "",
    accountType:"",
    referalOrCorp: "",
    referalOrCorpId: "",
    corpEmp: "",
    corpPo: "",
    corpReq: "",
    corporateMeta: [],
    status: "",
    taxMethod: "",
    taxAmount: 0,
    taxName: "",
    paymentMethod: "",
    totalTendered: 0,
    totalChange: 0,
    invoiceServices: [],
    invoicePayment: [],
  };
  
  private receiptStacked: ReceiptType [] = [];
  private checkTxn;
  private dateValue = "";
  private localStorageKey = "storeReceiptsStitchIt";
  private timeValue = "";
  private express = false;
  private store = useStore();

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.checkTxn = new CheckinService();
    this.toast = new Toaster();
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  emitPauseDetail() {
    this.$emit("emitPauseEvent", "close");
    this.pauseDialog = false;
  }

  formatDate(value) {
    if (value) {
      return moment(String(value)).format("DD-MM-YYYY");
    }
  }

  formatAmount(value) {
    value = Number(value);

    let balance = 0;

    if (value != "") {
      balance = value.toFixed(2);
    }
    return balance;
  }


  get savedReceipts()
  {

    this.receiptStacked = [];

    const oldReceipts = String(localStorage.getItem(this.localStorageKey));

    let parsedReceipt = JSON.parse(oldReceipts);

    if(parsedReceipt == null)
    {
        parsedReceipt = [];
    }
    this.receiptStacked = parsedReceipt;

    return this.receiptStacked;
  }

  pauseMyReceipt()
  {
    this.receiptStacked.push(this.customerReceipt);

    localStorage.setItem(this.localStorageKey,JSON.stringify(this.receiptStacked));

    this.toast.showSuccess('Receipt Paused Successfully');

    this.$emit("emitPauseEvent", ["paused",{}]);

    this.pauseDialog = false;
  }

  resumeMyReceipt(receipt,index)
  {
      if(this.customerReceipt.invoiceServices.length > 0)
      {
        this.toast.showInfo('Please clear the  current details to process futher');

        this.$emit("emitPauseEvent", "close");
        this.pauseDialog = false;
      }
      else
      {
        this.$emit("emitPauseEvent", ["resume",receipt]);
        this.pauseDialog = false;

        this.receiptStacked.splice(index,1);
        localStorage.setItem(this.localStorageKey,JSON.stringify(this.receiptStacked));
        this.toast.showSuccess('Receipt resumed successfully');
      }
  }

  removeMyReceipt()
  {
    this.receiptStacked.splice(this.tempPausedIndex,1);
    this.statusDialog = false;
    localStorage.setItem(this.localStorageKey,JSON.stringify(this.receiptStacked));
    this.toast.showSuccess('Receipt removed successfully');
  }

  openConfirmationDialog(index)
  {
      this.tempPausedIndex = index;
      this.statusDialog = true;
  }
}
